import axiosService from './Request';
import { ApiUrls, Constants } from '../constants';
import { ContractHeimdall, PostContractRequest, SiteConfiguration } from "../types";

export class ContractsService {
    public static async getPermissionsContracts(code: string, currentPage?: number, itemsPerPage?: number) {
        const body = {
            currentPage: currentPage ?? 1,
            itemsPerPage: itemsPerPage ?? Constants.DEFAULT_PER_PAGE
        }

        return axiosService.getWithParams(ApiUrls.PERMISSIONS_ADMIN, body, false, true)
            .then(response => response.data)
            .then(response => response[code]);
    }

    public static async getContractsInfo() {
        return axiosService.get(ApiUrls.CONTRACT_INFO_URL, false, true)
            .then(response => response.data);
    }

    public static async getContracts(currentPage?: number, itemsPerPage?: number, q?: string) {
        const body = {
            currentPage: currentPage ?? 1,
            itemsPerPage: itemsPerPage ?? Constants.NOTIFICATIONS_PER_PAGE,
            q: q
        }

        return axiosService.getWithParams(ApiUrls.CONTRACTS, body, false, true)
            .then(response => response);
    }

    public static async postContract(request: PostContractRequest) {
        return axiosService.post(ApiUrls.CONTRACT, request)
            .then(response => response.status)
    }

    public static getContractById(contractId: string) {
        const endpoint = ApiUrls.CONTRACT_BY_ID.replace(':contractId', contractId);
        const body = {
            contractId: contractId
        }
        return axiosService.getWithParams(endpoint, body, false, true)
            .then(response => response.data)
    }

    public static getContractFromFirstPermission(permissions: any[]) {
        const contract: ContractHeimdall = permissions[0].contract;
        return ContractsService.getContractById(contract.id);
    }

    public static getSiteConfiguration(contractId: string) {
        const url = ApiUrls.SITE_CONFIGURATION.replace(":contractId", contractId);
        return axiosService.get(url, false, true);
    }

    public static async createSiteConfiguration(siteConfiguration: SiteConfiguration) {
        const url = ApiUrls.SITE_CONFIGURATION.replace(":contractId", siteConfiguration.contract);
        return axiosService.post(url, siteConfiguration)
            .then(response => response.data)
    }

    public static deleteContract(contractId: string)
    {
        const url = ApiUrls.CONTRACT_BY_ID.replace(":contractId", contractId);
        return axiosService.delete(url, [], false, false);
    }

    public static async updateSiteConfiguration(siteConfiguration: SiteConfiguration) {
        const url = ApiUrls.SITE_CONFIGURATION.replace(":contractId", siteConfiguration.contract);
        return axiosService.put(url, siteConfiguration)
            .then(response => response.data)
    }

    public static async getContractsByUser(currentPage?: number, itemsPerPage?: number) {
        const body = {
            currentPage: currentPage ?? Constants.DEFAULT_CURRENT_PAGE,
            itemsPerPage: itemsPerPage ?? Constants.DEFAULT_PER_PAGE
        }
        return axiosService.getWithParams(ApiUrls.CONTRACTS_BY_USER, body, false, true)
    }
}
