import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import {SzInput, SzSelect, SzTypographie} from "@suezenv/react-theme-components";
import { useTranslation } from "react-i18next";
import { ApplicationConfigurationFormProps } from "../../../../types";
import {useSelector} from "react-redux";

type ApplicationParameters = {
    btnLabel: string
    authenticationMode: string
    contractId: string
    powerBiUser: string
    powerBiPassword: string
    clientId: string
    tenantId: string|null
    workspaceId: string
    reportId: string
    usernameFilter: string
    rolesFilter: []
};

const serviceName = "REPORT_TRENDS";

export const REPORT_TRENDS = (props: ApplicationConfigurationFormProps) => {
    const { serviceConfigurations, setServiceConfigurations, owner, ownerType } = props;
    const { t } = useTranslation();
    const [btnLabel, setBtnLabel] = useState<string>("undefined" !== typeof serviceConfigurations[serviceName] ? serviceConfigurations[serviceName].parameters?.btnLabel ?? "" : "");
    const [authenticationMode, setAuthenticationMode] = useState<string>("undefined" !== typeof serviceConfigurations[serviceName] ? serviceConfigurations[serviceName].parameters?.authenticationMode ?? "" : "");
    const [contractId, setContractId] = useState<string>("undefined" !== typeof serviceConfigurations[serviceName] ? serviceConfigurations[serviceName].parameters?.contractId ?? "" : "");
    const [powerBiUser, setPowerBiUser] = useState<string>("undefined" !== typeof serviceConfigurations[serviceName] ? serviceConfigurations[serviceName].parameters?.powerBiUser ?? "" : "");
    const [powerBiPassword, setPowerBiPassword] = useState<string>("undefined" !== typeof serviceConfigurations[serviceName] ? serviceConfigurations[serviceName].parameters?.powerBiPassword ?? "" : "");
    const [clientId, setClientId] = useState<string>("undefined" !== typeof serviceConfigurations[serviceName] ? serviceConfigurations[serviceName].parameters?.clientId ?? "" : "");
    const [tenantId, setTenantId] = useState<string>("undefined" !== typeof serviceConfigurations[serviceName] ? serviceConfigurations[serviceName].parameters?.tenantId ?? "" : "");
    const [workspaceId, setWorkspaceId] = useState<string>("undefined" !== typeof serviceConfigurations[serviceName] ? serviceConfigurations[serviceName].parameters?.workspaceId ?? "" : "");
    const [reportId, setReportId] = useState<string>("undefined" !== typeof serviceConfigurations[serviceName] ? serviceConfigurations[serviceName].parameters?.reportId ?? "" : "");
    const [usernameFilter, setUsernameFilter] = useState<string>("undefined" !== typeof serviceConfigurations[serviceName] ? serviceConfigurations[serviceName].parameters?.usernameFilter ?? "" : "");
    const [rolesFilter, setRolesFilter] = useState<[]>("undefined" !== typeof serviceConfigurations[serviceName] ? serviceConfigurations[serviceName].parameters?.rolesFilter ?? [] : []);
    const currentContract = useSelector((state: any) => state.contract.currentContract)
    const authenticationModeOptions = [
        {
            'label': 'MasterUser',
            'value': 'masteruser'
        },
        {
            'label': 'ServicePrincipal',
            'value': 'serviceprincipal'
        }
    ]
    const rolesFilterOptions = [
        {
            'label': 'Dynamic2',
            'value': 'Dynamic2'
        }
    ]

    const parameters = useRef<ApplicationParameters>({
        btnLabel: btnLabel,
        authenticationMode: authenticationMode,
        contractId: contractId,
        powerBiUser: powerBiUser,
        powerBiPassword: powerBiPassword,
        clientId: clientId ?? currentContract.id,
        tenantId: tenantId,
        workspaceId: workspaceId,
        reportId: reportId,
        usernameFilter: usernameFilter,
        rolesFilter: rolesFilter
    });

    useEffect(() => {
        parameters.current = {
            ...parameters.current,
            btnLabel,
            authenticationMode,
            contractId,
            powerBiUser,
            powerBiPassword,
            clientId,
            tenantId,
            workspaceId,
            reportId,
            usernameFilter,
            rolesFilter
        }
            if (
                "undefined" === typeof serviceConfigurations[serviceName] ||
                "undefined" !== typeof serviceConfigurations[serviceName] &&
                (
                    parameters.current.btnLabel !== serviceConfigurations[serviceName].parameters?.btnLabel ||
                    parameters.current.authenticationMode !== serviceConfigurations[serviceName].parameters?.authenticationMode ||
                    parameters.current.contractId !== serviceConfigurations[serviceName].parameters?.contractId ||
                    parameters.current.powerBiUser !== serviceConfigurations[serviceName].parameters?.powerBiUser ||
                    parameters.current.powerBiPassword !== serviceConfigurations[serviceName].parameters?.powerBiPassword ||
                    parameters.current.clientId !== serviceConfigurations[serviceName].parameters?.clientId ||
                    parameters.current.tenantId !== serviceConfigurations[serviceName].parameters?.tenantId ||
                    parameters.current.workspaceId !== serviceConfigurations[serviceName].parameters?.workspaceId ||
                    parameters.current.reportId !== serviceConfigurations[serviceName].parameters?.reportId ||
                    parameters.current.usernameFilter !== serviceConfigurations[serviceName].parameters?.usernameFilter ||
                    parameters.current.rolesFilter !== serviceConfigurations[serviceName].parameters?.rolesFilter
                )
            ) {
                setServiceConfigurations({
                    ...serviceConfigurations, [serviceName]: {
                        displayButtonForceChildConfiguration: true,
                        name: serviceName,
                        owner: owner,
                        ownerType: ownerType,
                        parameters: {
                            btnLabel: parameters.current.btnLabel,
                            authenticationMode: parameters.current.authenticationMode,
                            contractId: parameters.current.contractId,
                            powerBiUser: parameters.current.powerBiUser,
                            powerBiPassword: parameters.current.powerBiPassword,
                            clientId: parameters.current.clientId,
                            tenantId: parameters.current.tenantId,
                            workspaceId: parameters.current.workspaceId,
                            reportId: parameters.current.reportId,
                            usernameFilter: parameters.current.usernameFilter,
                            rolesFilter: parameters.current.rolesFilter,
                        }
                    }
                });
            }
    }, [btnLabel, authenticationMode, contractId, powerBiUser, powerBiPassword, clientId, tenantId, workspaceId, reportId, usernameFilter, rolesFilter]);

    return <div>
        <SzSelect
            id={'authenticationMode'}
            placeholder={t('admin.services.configuration.form.authentication_mode')}
            options={authenticationModeOptions}
            value={authenticationMode}
            onChange={selectedOption => setAuthenticationMode(selectedOption)}
            isSearchable={false}
            name={'authenticationMode'}
            required={true}
            disabled={false}
        />
        <SzInput
            value={btnLabel}
            name={`${serviceName}.btnLabel`}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setBtnLabel(e.target.value)}
            label={t('admin.services.configuration.form.btnLabel')}
            required={true}
        />
        <SzInput
            value={contractId}
            name={`${serviceName}.btnLabel`}
            onChange={() => setContractId(currentContract.id)}
            label={t('admin.services.configuration.form.powerbi_contract_id')}
        />
        <SzInput
            value={powerBiUser}
            name={`${serviceName}.btnLabel`}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setPowerBiUser(e.target.value)}
            label={t('admin.services.configuration.form.powerbi_username')}
        />
        <SzInput
            value={powerBiPassword}
            name={`${serviceName}.btnLabel`}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setPowerBiPassword(e.target.value)}
            label={t('admin.services.configuration.form.powerbi_password')}
        />
        <SzInput
            value={tenantId}
            name={`${serviceName}.btnLabel`}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setTenantId(e.target.value)}
            label={t('admin.services.configuration.form.powerbi_tenant_id')}
        />
        <SzInput
            value={clientId}
            name={`${serviceName}.btnLabel`}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setClientId(e.target.value)}
            label={t('admin.services.configuration.form.azure_client_id')}
        />
        <SzInput
            value={workspaceId}
            name={`${serviceName}.btnLabel`}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setWorkspaceId(e.target.value)}
            label={t('admin.services.configuration.form.powerbi_group_id')}
        />
        <SzInput
            value={reportId}
            name={`${serviceName}.btnLabel`}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setReportId(e.target.value)}
            label={t('admin.services.configuration.form.powerbi_report_id')}
        />
        <SzInput
            value={usernameFilter}
            name={'usernameFilter'}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setUsernameFilter(e.target.value)}
            label={t('admin.services.configuration.form.powerbi_username_filter')}
        />
        <SzTypographie
            className={"mb-0 sz-form-group__label"}
            color="inactive"
            weight="bold"
            tag="span"
        >
            {t('admin.services.configuration.form.powerbi_roles_filter')}
        </SzTypographie>
        <SzSelect
            id={'rolesFilter'}
            placeholder={''}
            options={rolesFilterOptions}
            value={rolesFilter}
            onChange={selectedOption => setRolesFilter(selectedOption)}
            name={'rolesFilter'}
            isMulti
            isClearable
            isSearchable
        />
    </div>
}
