import React, { Suspense, useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from "react-query";
import { ConnectedRouter } from 'connected-react-router';
import cssVars from 'css-vars-ponyfill';
import { SzSpinner } from "@suezenv/react-theme-components"
import { History } from 'history';
import { store } from './store';
import { config } from "./config";
import { ErrorRoutes } from './constants';
import NavigationManager from './manager/navigation';
import { AuthenticateService } from './services/AuthenticateService';
import LoadingPage from "./pages/loadingPage";
import RoutesManager from "./routes";
import CurrentContractManager from "./manager/contracts/currentContract";
import ThemeProvider from './manager/themes';
import "tools/datadog";

cssVars({
    onlyLegacy: true,
});

interface IAppProps {
    history: History;
}

const AppRouter = ({ history }: IAppProps) => {
    const [isLogged, setIsLogged] = useState<boolean>(false)
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
            },
        },
    });

    useEffect(() => {
        const pathName = history.location.pathname;
        const token = localStorage.getItem(config.tokenKey);
        if ((!token || token === 'undefined') && pathName !== ErrorRoutes.SSO_ERROR) {
            AuthenticateService.login()
                .then(() => { setIsLogged(true) })
        } else if (pathName !== ErrorRoutes.SSO_ERROR) {
            AuthenticateService.authenticate()
                .then(() => { setIsLogged(true) })
        }
    }, [history]);

    if (!isLogged) {
        return <SzSpinner />
    }

    return (isLogged &&
        <Suspense fallback={<LoadingPage/>}>
            <QueryClientProvider client={queryClient}>
                <Provider store={store}>
                    <ConnectedRouter history={history}>
                        <CurrentContractManager>
                            <ThemeProvider>
                                <NavigationManager>
                                    <RoutesManager/>
                                </NavigationManager>
                            </ThemeProvider>
                        </CurrentContractManager>
                    </ConnectedRouter>
                </Provider>
            </QueryClientProvider>
        </Suspense>
    )
}

export default AppRouter;
