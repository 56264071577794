import axiosService from './Request';
import { ApiUrls, Constants } from '../constants';

export class AdministrativeDivisionService {
    public static getAdministrativeDivisions(query: string, countryCode: string, currentPage?: number, itemsPerPage?: number) {
        const body = {
            q: query,
            countryCode: countryCode || 'FR',
            currentPage: currentPage ?? 1,
            itemsPerPage: itemsPerPage ?? Constants.NOTIFICATIONS_PER_PAGE
        }

        return axiosService.getWithParams(ApiUrls.ADMINISTRATIVE_DIVISIONS, body, false, true)
            .then(response => response);
    }
}
