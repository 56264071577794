import { Constants } from "../constants";
import { Middleware } from "redux";
import { PermissionsService } from "../services/PermissionsService";

const permissionsServiceMiddleWare: Middleware = store => next => action => {
    next(action);

    if (Constants.USER_PERMISSIONS === action.type) {
        const { permissions }: { permissions: any } = store.getState();
        PermissionsService.checkRedirect(permissions.permissions);
    }
};

export default permissionsServiceMiddleWare;
