import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery, useQueryClient } from "react-query";
import { ApplicationConfiguration, Organization } from "../../../../types";
import { OrganizationService } from "../../../../services/OrganizationService";
import { SzStepper } from "@suezenv/react-theme-components";
import FormSuccessPage from "../../FormSuccessPage";
import successPicture from "../../../../assets/img/contract/creation_success.svg";
import Step2 from "../../services/steps/step2";
import { ApplicationConfigurationService } from "../../../../services/ApplicationConfigurationService";
import Step1 from "./steps/step1";

type PropsType = {
    organizationId: string
    refreshServices: () => void,
}

const AdminOrganizationAddService = (props: PropsType) => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const { organizationId, refreshServices } = props;
    const [selectedServices, setSelectedServices] = useState<string[]>([]);
    const [selectedContractServices, setSelectedContractServices] = useState<string[]>([]);
    const [serviceConfigurations, setServiceConfigurations] = useState<{ [key: string]: ApplicationConfiguration }>({});
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [isSuccess, setSuccess] = useState<boolean>(false);
    const [step, setStep] = useState<number>(0);
    const [parentConfs, setParentConfs] = useState<{ [serviceCode: string]: string }>({});

    const fetchOrganization = () => OrganizationService.getOrganization(organizationId);
    const { data: organization } = useQuery<Organization>(['organization', organizationId], fetchOrganization);

    const fetchContractConfigurations = () => organization?.contractId ? ApplicationConfigurationService.getByOwnerAndType(organization.contractId, "CONTRACT") : null;
    const { data: contractConfigurations } = useQuery(['contractApplicationConfiguration', organization?.contractId], fetchContractConfigurations, { enabled: typeof organization !== "undefined" })


    useEffect(() => {
        if(contractConfigurations?.data) {
            let configurations = parentConfs;
            contractConfigurations.data.map((conf: ApplicationConfiguration) => {
                    if (conf.id) {
                        configurations = { ...configurations, [conf.name]: conf.id }
                    }
                }
            )
            setParentConfs(configurations);
        }

    }, [contractConfigurations]);

    const onValidate = () => {
        // first filter from conf services that can have a conf but have been selected THEN unselected.
        setIsSaving(true);
        let tmpServiceConfigurations = serviceConfigurations;
        Object.keys(tmpServiceConfigurations).forEach((serviceName: string) => {
            if (typeof selectedServices.find((serviceSelected: string) => serviceSelected === serviceName) === "undefined") {
                delete tmpServiceConfigurations[serviceName];
            }
        });
        setServiceConfigurations(tmpServiceConfigurations);
        // Then we save service and service configurations

        OrganizationService.updateOrganizationServices(organizationId, selectedContractServices)
            .then(() => {
                let promises: Promise<any>[] = [];
                if (serviceConfigurations && Object.keys(serviceConfigurations).length > 0) {
                    Object.values(serviceConfigurations).forEach((conf: ApplicationConfiguration) => {
                        if (conf.useParentConfiguration) {
                            conf.parentConfiguration = parentConfs[conf.name] ?? "";
                        }
                        promises.push(ApplicationConfigurationService.saveApplicationConfiguration(conf));
                    });
                }
                Promise.all(promises).then(() => {
                    if (refreshServices) {
                        refreshServices();
                    }
                    queryClient.invalidateQueries("organizationServices");
                    setSuccess(true);
                    setIsSaving(false);
                });
            });

    }

    const resetForm = () => {
        setStep(0);
        setSelectedServices([]);
        setServiceConfigurations({});
        setIsSaving(false);
        setSuccess(false);
    }

    const stepperHeader = [
        t("admin.services.modal.add.serviceChoice"),
        t("admin.services.modal.add.serviceConfiguration")
    ];

    const stepper = <SzStepper header={stepperHeader} activeStep={step}>
        <SzStepper.SzHeader/>
        <SzStepper.SzStep index={0}>
            <Step1
                setSelectedServices={setSelectedServices}
                setSelectedContractServices={setSelectedContractServices}
                organizationId={organizationId}
                goToNext={() => setStep(1)}
            />
        </SzStepper.SzStep>
        <SzStepper.SzStep index={1}>
            <Step2
                contractId={organization?.contractId}
                ownerId={organizationId}
                ownerType={"ORGANIZATION"}
                selectedServices={selectedServices}
                serviceConfigurations={serviceConfigurations}
                setServiceConfigurations={setServiceConfigurations}
                parentConfigurations={parentConfs}
                goToPrev={() => setStep(0)}
                onValidate={onValidate}
                isSaving={isSaving}
            />
        </SzStepper.SzStep>
    </SzStepper>

    const successComponent = <FormSuccessPage
        image={successPicture}
        title={t('admin.organization.organizationService.modal.service_added')}
        subtitle={t('admin.organization.organizationService.modal.service_added_subtext')}
        middleBottomButtonIcon={"add-circle"}
        middleBottomButtonOnclick={resetForm}
        middleBottomButtonText={t('admin.organization.organizationService.modal.add_new_service')}
    />

    return isSuccess ? successComponent : stepper;
}

export default AdminOrganizationAddService;