import React, {useState} from "react";
import { SzStepper } from "@suezenv/react-theme-components";
import { useTranslation } from "react-i18next";
import { ApplicationConfiguration } from "../../../types";
import successPicture from "../../../assets/img/contract/creation_success.svg";
import FormSuccessPage from "../FormSuccessPage";
import { ContractServiceService } from "../../../services/ContractServiceService";
import Step1 from "./steps/step1";
import Step2 from "./steps/step2";
import { ApplicationConfigurationService } from "../../../services/ApplicationConfigurationService";

const AdminServiceAdd = (props: { currentContract: string, refreshData?: () => void }) => {
    const { currentContract, refreshData } = props;
    const { t } = useTranslation();
    const [selectedServices, setSelectedServices] = useState<string[]>([]);
    const [serviceConfigurations, setServiceConfigurations] = useState<{[key: string]: ApplicationConfiguration}>({});
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [isSuccess, setSuccess] = useState<boolean>(false);
    const [step, setStep] = useState<number>(0);

    const stepperHeader = [
        t("admin.services.modal.add.serviceChoice"),
        t("admin.services.modal.add.serviceConfiguration")
    ];

    const onValidate = () => {
        // first filter from conf services that can have a conf but have been selected THEN unselected.
        setIsSaving(true);

        let tmpServiceConfigurations = serviceConfigurations;
        Object.keys(tmpServiceConfigurations).forEach((serviceName: string) => {
            if (typeof selectedServices.find((serviceSelected: string) => serviceSelected === serviceName) === "undefined") {
                delete tmpServiceConfigurations[serviceName];
            }
        });

        setServiceConfigurations(tmpServiceConfigurations);
        // Then we save service and service configurations
        ContractServiceService.createContractServices(currentContract, selectedServices)
            .then(() => {
                let promises: Promise<any>[] = [];
                if(serviceConfigurations && Object.keys(serviceConfigurations).length > 0) {
                    Object.values(serviceConfigurations).forEach((conf: ApplicationConfiguration) => {
                        if (!conf.forceChildConfiguration) {
                            promises.push(ApplicationConfigurationService.saveApplicationConfiguration(conf));
                        }
                    })
                }
                Promise.all(promises).then(() => {
                    if (refreshData) {
                        refreshData();
                    }
                    setSuccess(true);
                    setIsSaving(false);
                });
            });
    }

    const resetForm = () => {
        setStep(0);
        setSelectedServices([]);
        setServiceConfigurations({});
        setIsSaving(false);
        setSuccess(false);
    }

    const successComponent = <FormSuccessPage
        image={successPicture}
        title={t('admin.services.modal.add.success.services_created')}
        subtitle={t('admin.services.modal.add.success.services_created_subtext')}
        middleBottomButtonIcon={"add-circle"}
        middleBottomButtonOnclick={resetForm}
        middleBottomButtonText={t('admin.services.modal.add.success.create_new_service')}
    />

    const stepper = <SzStepper header={stepperHeader} activeStep={step}>
        <SzStepper.SzHeader/>
        <SzStepper.SzStep index={0}>
            <Step1
                setSelectedServices={setSelectedServices}
                contractId={currentContract}
                goToNext={() => setStep(1)}
            />
        </SzStepper.SzStep>
        <SzStepper.SzStep index={1}>
            <Step2
                contractId={currentContract}
                ownerId={currentContract}
                ownerType={"CONTRACT"}
                selectedServices={selectedServices}
                serviceConfigurations={serviceConfigurations}
                setServiceConfigurations={setServiceConfigurations}
                goToPrev={() => setStep(0)}
                onValidate={onValidate}
                isSaving={isSaving}
            />
        </SzStepper.SzStep>
    </SzStepper>

    return isSuccess ? successComponent : stepper;

}

export default AdminServiceAdd;
