import React, { useCallback, useState } from "react";
import { SzButton } from "@suezenv/react-theme-components";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { AdministrativeDivisionHeimdall } from "../../../types";
import SzDualListBox, { SzDualListBoxOption } from "../../elements/SzDualListBox";
import { AdministrativeDivisionService } from "../../../services/AdministrativeDivisionService";
import { AreaService } from "../../../services/AreaService";
import successPicture from "../../../assets/img/contract/creation_success.svg";
import FormSuccessPage from "../FormSuccessPage";

const AdminAreasAdd = (props: { currentContract: string, countryCode: string, refreshData?: () => void }) => {
    const { currentContract, countryCode } = props;
    const debounceSearchDelay = 500;
    const { t } = useTranslation();
    const [options, setOptions] = useState<SzDualListBoxOption[]>([]);
    const [optionCount, setOptionCount] = useState<number>(0);
    const [selectedOptions, setSelectedOptions] = useState<SzDualListBoxOption[]>([]);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [isSuccess, setSuccess] = useState<boolean>(false);

    const fetchFilteredAdministrativeDivisions = (keywords: string) => {
        return AdministrativeDivisionService.getAdministrativeDivisions(keywords, countryCode, 1, 100)
            .then((response) => {
                const tmpOptions = response.data.map((administrativeDivision: AdministrativeDivisionHeimdall) => {
                    return { id: administrativeDivision.id as string, label: `${administrativeDivision.label} ${administrativeDivision.postalCode ? `(${administrativeDivision.postalCode})` : ""}` }
                })
                const totalResult = parseInt(response.headers['x-pagination-total-count']) ?? 0;
                setOptionCount(totalResult);
                setOptions(tmpOptions);
            });
    }

    const onKeywordsChange = (keywords: string) => {
        if (keywords.length >= 3) {
            debouncedSearch(keywords);
        }
    }

    const debouncedSearch = useCallback(_.debounce(
        (keywords: string) => fetchFilteredAdministrativeDivisions(keywords),
        debounceSearchDelay
    ), []);

    const onValidate = () => {
        setIsSaving(true);
        const administrativeDivisions = selectedOptions.map((option: SzDualListBoxOption) => {
            return option.id;
        });
        AreaService.createAreaAndContractArea(currentContract, administrativeDivisions)
            .then(() => setSuccess(true))
            .then(() => {
                if (props.refreshData) {
                    props.refreshData();
                }
            });
    }

    const resetForm = () => {
        setOptions([]);
        setSelectedOptions([]);
        setOptionCount(0);
        setIsSaving(false);
        setSuccess(false);
    }
    
    const successComponent = <FormSuccessPage
        image={successPicture}
        title={t('admin.area.modal.add.success.area_created')}
        subtitle={t('admin.area.modal.add.success.area_created_subtext')}
        middleBottomButtonIcon={"add-circle"}
        middleBottomButtonOnclick={resetForm}
        middleBottomButtonText={t('admin.area.modal.add.success.create_new_area')}
        />

    const addComponent = <div>
        <div className="col-12">
            <SzDualListBox
                options={options}
                selectedOptions={selectedOptions}
                showRowIcons={true}
                onlySelectWithRowIcons={true}
                leftSideTitle={t("admin.area.modal.add.allMunicipalities")}
                rightSideTitle={t("admin.area.modal.add.selectedMunicipalities")}
                searchPlaceholder={t("admin.area.modal.add.searchPlaceholder")}
                onKeywordsChange={onKeywordsChange}
                onChange={setSelectedOptions}
                maxDisplayedOption={15}
                optionCount={optionCount}
                showResultCount={true}
            />
        </div>
        <div className="col-12 d-flex justify-content-end">
            <SzButton
                onClick={onValidate}
                loader={isSaving}
                variant={"primary"}
                isDisabled={selectedOptions.length <= 0 }
            >
                {t('admin.area.modal.add.validate')}
            </SzButton>
        </div>
    </div>;

    return isSuccess ? successComponent : addComponent;

}

export default AdminAreasAdd;
