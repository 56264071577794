import React, {useEffect} from "react";
import {useMap} from "react-leaflet";

const FitBoundMap: React.FC<any> = ({bounds}) => {
    const map = useMap();
    useEffect(() => {
        if(bounds.isValid()) {
            map.fitBounds(bounds);
        }
    }, [bounds]);
    return null;
};

export default FitBoundMap;
