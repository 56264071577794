import { Constants } from "../constants";

// actions 
export const showModal = (tab: string) => ({
    type: Constants.UI_SHOW_MODAL,
    show: true,
    tab
});

export const hideModal = () => ({
    type: Constants.UI_HIDE_MODAL
});