import { Constants } from "../constants";
import { UiDefaultState } from "../types";

const uiDefaultState: UiDefaultState = {
    userModal: {
        show: false,
        tab: null
    }
};

const userReducer = (state = uiDefaultState, action: any) => {
    switch (action.type) {
        case Constants.UI_SHOW_MODAL:
            return { ...state, userModal: { show: true, tab: action.tab } };
        case Constants.UI_HIDE_MODAL:
            return { ...state, userModal: { show: false, tab: null } };
        default:
            return state;
    }
};

export default userReducer;
