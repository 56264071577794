import { currentContract, login, logout } from '../actions';
import { ApiUrls, AppUrls } from '../constants';
import qs from "qs";
import axiosService from './Request';
import { store } from '../store';
import { config } from "../config";
import { UserContract } from "../types";
import { ContractsService } from "./ContractsService";
import { ThemeService } from "./ThemeService";
import { UserConfigurationService } from './UserConfigurationService';

export class AuthenticateService {
    public static async login() {
        const { token } = qs.parse(window.location.search.substring(1));
        if (!token) {
            localStorage.setItem("redirect-portal-extranet", window.location.href);
            window.location = AppUrls.SAML_LOGIN as any;
        } else {
            localStorage.setItem(config.tokenKey, token.toString());
            let redirectHref = localStorage.getItem("redirect-portal-extranet");
            localStorage.removeItem("redirect-portal-extranet");
            await this.authenticate()
            window.location = redirectHref ? redirectHref as any : AppUrls.HOME;
        }
    }

    public static logout() {
        localStorage.clear();
        store.dispatch(logout());
        window.location = AppUrls.SAML_LOGOUT as any;
    }

    public static async authenticate() {
        axiosService.get(ApiUrls.TOKEN_INFO_URL, false)
            .then(response => store.dispatch(login(response.data)));

        let currentConfig = await UserConfigurationService.getCurrentConfigurationContract()
        if (currentConfig) {
            this.setCurrentContract(currentConfig)
        } else {
            let contracts: UserContract[] = await ContractsService.getContractsByUser().then((response) => response.data)
            let firstContract = this.getFirstContract(contracts)
            if (firstContract) {
                this.setCurrentContract(firstContract)
                UserConfigurationService.storeCurrentContractConfiguration(firstContract)
            }
        }
    }

    private static getFirstContract(contracts: UserContract[]): UserContract|undefined {
        if (contracts.length > 0) {
            return contracts.slice().shift();
        }
    }

    private static setCurrentContract(contract: UserContract): void {
        ThemeService.setTheme(contract);
        store.dispatch(currentContract(contract));
    }
}
