import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SzDualListBox, { SzDualListBoxOption } from "../../../elements/SzDualListBox";
import { useQuery } from "react-query";
import { Contract, ContractService } from "../../../../types";
import { ContractServiceService } from "../../../../services/ContractServiceService";

type PropsType = {
    contract: Contract,
    setContractServices: (contractServiceId: string[]) => void,
    contractServices: string[],
    setSelectedServices: (serviceCode: string[]) => void,
    skillSets: string[],
    defaultSelectedServices: SzDualListBoxOption[]|undefined
}

const Step4 = (props: PropsType) => {
    const { t } = useTranslation();
    const [options, setOptions] = useState<SzDualListBoxOption[]>([]);
    const [selectedOptions, setSelectedOptions] = useState<SzDualListBoxOption[]>([]);

    const fetchContractServices = () => {
        return ContractServiceService.getAllByContract(props.contract.id, 1, 9999, props.skillSets, true);
    }

    const { data : contractServicesData } = useQuery(["contractService", props.contract.id, props.skillSets], fetchContractServices);

    const contractServicesDataToOption = (contractServices: ContractService[]) => {
        return  contractServices.map((cs: ContractService) => {
            return { id: cs.id, label: cs.service.label }
        });
    }

    const filterServiceOptions = (filter: string) => {
        if (contractServicesData) {
            setOptions(contractServicesDataToOption(contractServicesData.data).filter((option: SzDualListBoxOption) => option.label.toLowerCase().indexOf(filter.toLowerCase()) !== -1))
        }
    }

    useEffect(
        () => {
            if (props.defaultSelectedServices !== undefined) {
                setSelectedOptions(props.defaultSelectedServices)
            }

            if (contractServicesData) {
                setOptions(contractServicesDataToOption(contractServicesData.data));
            }

        }, [contractServicesData, props.defaultSelectedServices]
    );

    useEffect(() => {
        props.setContractServices(selectedOptions.map((selected: SzDualListBoxOption) => selected.id ?? selected.code ));
        props.setSelectedServices(selectedOptions.map((selected: SzDualListBoxOption) => {
            const selectedContractService = contractServicesData?.data?.find((contractService: ContractService) => contractService.id == selected.id);
            if (selectedContractService) {
                return selectedContractService.service.code;
            }
            return;
        }));
    }, [selectedOptions])

    return <SzDualListBox
        options={options}
        selectedOptions={selectedOptions}
        showRowIcons={true}
        onlySelectWithRowIcons={true}
        leftSideTitle={t("admin.organization.modal.add.allServices")}
        rightSideTitle={t("admin.organization.modal.add.selectedServices")}
        onChange={setSelectedOptions}
        isScrollable={true}
        onKeywordsChange={filterServiceOptions}
        searchPlaceholder={t('search')}
        />
}

export default Step4;