import React, { useState } from 'react';
import { SzList } from "@suezenv/react-theme-components";
import { useTranslation } from "react-i18next";
import Indicators from "./tabs/indicator";
import "./userPreferencesModal.scss";

const UserPreferencesModal: React.FunctionComponent = () => {

    const { t } = useTranslation();

    const tabsList = [
        {
            label: 'indicator',
            component: <Indicators/>,
        },
        // {
        //     label: 'display_order',
        //     component: <>TODO Display Order</>,
        // },
        // {
        //     label: 'appearance',
        //     component: <>TODO Appearance</>,
        // }
    ]

    const [activeTab, setActiveTab] = useState(tabsList[0]);

    return <div className={"modal-tab-container"}>
        <SzList horizontal className="pt-3 d-flex justify-content-center">
            {tabsList.map((value: any) => {
                return (
                    <SzList.Item
                    onClick={() => setActiveTab(value)}
                    active={activeTab.label === value.label}
                    className={"border-0 ml-0 p-4 bg-none"}
                    >
                        {t(`modal.tab.${value.label}.label`)}
                    </SzList.Item>
                )
            })}
        </SzList>
        <div className={'modal-tab-content'}>
            {activeTab.component}
        </div>
    </div>
}

export default UserPreferencesModal;
