import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from "react-router";
import { Link, useLocation } from 'react-router-dom';
import { SzButton, SzDropDown, SzHeader, SzList } from "@suezenv/react-theme-components";
import { AppUrls } from '../../../constants';
import { ThemeState, User } from "../../../types";
import { useTheme } from "../../../hooks/theme";
import './header.scss';
import defaultLogo from "../../../assets/themes/villagile/Villagile_Produit_TexteColor.svg"

type IHeadersProps = RouteComponentProps & {
    isError: boolean;
    setModalShow: (show: boolean) => void;
    user?: User;
    showMenu: boolean;
    setShowMenu: (show: boolean) => void;
}

function Header(Props: IHeadersProps) {
    const { setModalShow, user, showMenu, setShowMenu } = Props;
    const { t } = useTranslation();
    const location = useLocation();
    const { appTheme } = useTheme()!;
    const [showUserMenu, setShowUserMenu] = useState(false);
    const [active, setActive] = useState(Props.location.pathname === AppUrls.CARTO ? 1 : 0);

    function useCloseDropdown(ref: MutableRefObject<any>) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleCLick(event: any) {
                if (ref.current && (!ref.current.contains(event.target) || event.target.closest(".sz-list_item"))) {
                    // timeout prevent closing menu before dropdown clivk event is triggered
                    setTimeout(() => setShowUserMenu(false), 50);
                }
            }

            // Bind the event listener
            document.addEventListener("mouseup", handleCLick);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mouseup", handleCLick);
            };
        }, [ref]);
    }

    const menuOpt = [
        {
            content: t('settings.user_profil'),
            link: AppUrls.USER_PROFILE,
            icon: 'sz-icon-line single-neutral-actions-information'
        },
        {
            content: t('settings.user_settings'),
            handleClick: () => {
                setModalShow(true);
            },
            icon: 'sz-icon-line app-window-settings'
        },
        {
            content: t('settings.cgu'),
            link: AppUrls.CGU,
        },
        {
            content: t('settings.legal_policies'),
            link: AppUrls.LEGAL_POLICIES,
        },
        {
            content: t('settings.cookies'),
            link: AppUrls.Cookies,
        },
        {
            content: t('settings.logout'),
            link: AppUrls.LOGOUT,
            icon: 'sz-icon-line logout-2'
        },
    ];

    const username = user ? <>{user.firstName} {user.lastName}</> : t('settings.user_profil')

    const renderLogoLink = () => {
        return <Link to={AppUrls.HOME} title={t(appTheme?.title ?? "")}>
            <img src={appTheme?.rectangleLogo ? appTheme.rectangleLogo : defaultLogo}
                 className="logo" alt='logo'/>
        </Link>;
    }

    const wrapperRef = useRef(null);
    useCloseDropdown(wrapperRef);

    const userMenuItem = () => {
        return menuOpt.map(({ link, handleClick, icon, content }, index) => {
            return (
                <SzDropDown.Item key={index} onClick={handleClick} icon={icon}>
                    {link ? (
                        <Link to={link} className="d-flex align-items-center text-decoration-none">
                            {content}
                        </Link>
                    ) : (
                        <>
                            {content}
                        </>
                    )}
                </SzDropDown.Item>
            );
        });
    }

    const userMenu = (
        <div ref={wrapperRef} onClick={() => setShowUserMenu(!showUserMenu)}>
            <SzDropDown
                className={"login d-sm-none"}
                value={""}
                show={showUserMenu}
            >
                {userMenuItem()}
            </SzDropDown>
            <SzDropDown
                className={"login d-none d-sm-block"}
                value={username}
                show={showUserMenu}
            >
                {userMenuItem()}
            </SzDropDown>
        </div>
    );

    const options = [
        {
            content: t('DASHBOARD_TAB'),
            link: AppUrls.HOME,
        },
        {
            content: t('INTERVENTION_MAP_TAB'),
            link: AppUrls.CARTO,
        },
    ];

    return (
        <header>
            <SzHeader className="d-flex justify-content-between">
                <SzButton className="group menu text-secondary d-block d-sm-none"
                          onClick={() => setShowMenu(!showMenu)}>
                    <span className="sz-icon-line navigation-menu"/>
                </SzButton>
                <div className={"d-none d-sm-block"}>
                    <div className="group">{renderLogoLink()}</div>
                </div>
                {(location.pathname === AppUrls.HOME || location.pathname === AppUrls.CARTO) &&
                    <div className="group no-border title">
                        <SzDropDown className={"d-block d-sm-none"} value={t('DASHBOARD_TAB')}>
                            {options.map(({ content, link }, index) => {
                                return (
                                    <SzDropDown.Item key={index}>
                                        <Link to={link}>{content}</Link>
                                    </SzDropDown.Item>
                                );
                            })}
                        </SzDropDown>
                        <div className={"d-none d-sm-block"}>
                            <SzList horizontal>
                                <SzList.Item
                                    onClick={() => setActive(0)}
                                    active={active === 0}
                                    className="border-0 p-0"
                                >
                                    <Link to={AppUrls.HOME} className="text-decoration-none">
                                        {t('DASHBOARD_TAB')}
                                    </Link>
                                </SzList.Item>
                                <SzList.Item
                                    onClick={() => setActive(1)}
                                    active={active === 1}
                                    className="border-0 p-0"
                                >
                                    <Link to={AppUrls.CARTO}
                                          className="text-decoration-none">{t('INTERVENTION_MAP_TAB')}</Link>
                                </SzList.Item>
                            </SzList>
                        </div>
                    </div>
                }
                <div className="group no-border">{userMenu}</div>
            </SzHeader>
        </header>
    );
}

const mapStateToProps = (state: { theme: ThemeState, user: { info: User } }) => ({
    primaryLogo: state.theme.primaryLogo,
    user: state.user.info
});

export default withRouter(connect(mapStateToProps)(Header));
