import React, { useEffect, useState } from 'react';
import { SzAlert, SzButton, SzCheckbox, SzTypographie } from "@suezenv/react-theme-components";
import { useTranslation } from "react-i18next";
import { Constants, ReactGridLayout } from "../../../constants";
import { UserConfiguration, Widget } from "../../../types";
import { UserConfigurationService } from "../../../services/UserConfigurationService";
import { useQuery, useQueryClient } from "react-query";
import { useUserWidgets } from "../../../hooks/useUserWidgets";

const Indicators = () => {
    const widgets = useUserWidgets();
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)
    const [loading, setLoading] = useState(false)
    const [widgetFamilies, setWidgetFamilies] = useState<{ [family: string]: Widget[] }>({});
    const [checkedWidgets, setCheckedWidgets] = useState<{ [key: string]: boolean }>({});

    const fetchUserDisplayedWidgets = () => UserConfigurationService.getUserConfiguration(Constants.DISPLAYED_WIDGETS_CONFIGURATION_CODE)
    const { data: displayedWidgets } = useQuery(Constants.DISPLAYED_WIDGETS_CONFIGURATION_CODE, fetchUserDisplayedWidgets)

    useEffect(() => {
        let tmpFamilies: { [family: string]: Widget[] } = {};
        widgets.map((widget: Widget) => {
            if (tmpFamilies[widget.family]) {
                tmpFamilies[widget.family] = tmpFamilies[widget.family].concat([widget]);
            } else {
                tmpFamilies[widget.family] = [widget];
            }
            return null;
        });
        setWidgetFamilies(tmpFamilies);
    }, []);

    useEffect(() => {
        const data = displayedWidgets?.data.data ? JSON.parse(displayedWidgets.data.data) : {};
        let initialCheckedWidgetsState: { [key: string]: boolean } = {};
        widgets.map((widget: Widget) => {
            if (
                //conf exists, widget conf exists & widget is active
                (data && data[widget.id])
                // conf exists, widget conf doesn't exist
                || (data && "undefined" === typeof data[widget.id])
                // conf doesn't exists
                || (!data)
            ) {
                initialCheckedWidgetsState = { ...initialCheckedWidgetsState, [widget.id]: true };
            } else {
                initialCheckedWidgetsState = { ...initialCheckedWidgetsState, [widget.id]: false };
            }
            return null;
        });
        setCheckedWidgets(initialCheckedWidgetsState);
    }, [displayedWidgets]);

    const toggleChecked = (widgetId: string) => {
        setCheckedWidgets({ ...checkedWidgets, [widgetId]: !checkedWidgets[widgetId] });
    };

    const saveUserWidgetConfiguration = () => {
        let confToSave: UserConfiguration = {
            portal: Constants.PORTAL_NAME as string,
            code: Constants.DISPLAYED_WIDGETS_CONFIGURATION_CODE as string,
            data: {}
        }
        Object.entries(checkedWidgets).map((widget: [string, boolean]) => {
            confToSave.data[widget[0]] = widget[1];
            return null;
        });
        setError(false);
        setSuccess(false);
        setLoading(true);
        UserConfigurationService.saveUserConfiguration(Constants.DISPLAYED_WIDGETS_CONFIGURATION_CODE, confToSave)
            .then(() => queryClient.invalidateQueries(Constants.DISPLAYED_WIDGETS_CONFIGURATION_CODE))
            .then(() => setSuccess(true))
            .catch(() => setError(true))
            .finally(() => setLoading(false));
    }

    return <div className={"d-flex flex-flow-column"}>

        {error && (
            <div className='col'>
                <SzAlert variant="danger" transparent={true}
                         onClose={() => setError(false)}>{t('alert.saving_error')}</SzAlert>
            </div>
        )}

        {success && (
            <div className='col'>
                <SzAlert variant="success" transparent={true}
                         onClose={() => setError(false)}>{t('alert.saving_success')}</SzAlert>
            </div>
        )}

        {Object.entries(widgetFamilies).map((family: [string, Widget[]], index: number, families: [string, Widget[]][]) => {
            //removing border of last category (:last-child / :last-of-type don't work because of btn container)
            const additionalClass = index === families.length - 1 ? "border-0" : "";
            return <div className={`indicators-form_category p-3 pb-2 ${additionalClass}`}>
                <SzTypographie variant={"h2"} weight={"bold"} className={"mb-2"}>
                    {t(`widget.family.${family[0]}`)}
                </SzTypographie>
                <div className="row">
                    {family[1].map((widget: Widget) => {
                        return <div className={`col-xs-${ReactGridLayout.COL_XS} col-sm-${ReactGridLayout.COL_MD}`}>
                            <SzCheckbox
                                id={widget.id}
                                type={"checkbox"}
                                checked={checkedWidgets[widget.id]}
                                name={widget.id}
                                onChange={() => toggleChecked(widget.id)}
                                label={widget.label}
                                className={"checkbox-big"}
                            />
                        </div>
                    })
                    }
                </div>
            </div>
        })}
        <div className={"indicators-form_btn-container d-flex justify-content-end bg-white py-4"}>
            <SzButton loader={loading} className={"indicators-form_btn-save"} onClick={saveUserWidgetConfiguration}>
                {!loading && t('modal.tab.save')}
            </SzButton>
        </div>
    </div>
}

export default Indicators;
