import Request from "./Request";
import { ApiUrls, Constants } from '../constants';
import { UserConfiguration, UserContract } from "../types";

export class UserConfigurationService {

    public static getUserConfiguration(configurationCode: string, portalCode: string = Constants.PORTAL_NAME, showDeleted = false) {
        const url = this.getURL(portalCode, configurationCode);
        const body = showDeleted ? { showDeleted: true } : null;
        
        return Request.getWithParams(url, body, false, true);
    }

    public static async postUserConfiguration(configuration: UserConfiguration, portalCode: string = Constants.PORTAL_NAME) {
        const url = this.getURL(portalCode);
        return Request.post(url, configuration, false, true)
            .then(response => response.data);
    }

    public static async putUserConfiguration(configurationCode: string, configuration: UserConfiguration, portalCode: string = Constants.PORTAL_NAME, editDeleted = false) {
        let url = this.getURL(portalCode, configurationCode);
        url += editDeleted ? '?editDeleted=true' : '';
        return Request.put(url, configuration, false, true)
            .then(response => response.data);
    }

    public static async saveUserConfiguration(configurationCode: string, configuration: UserConfiguration, portalCode: string = Constants.PORTAL_NAME) {
        //test if conf already exists
        return this.getUserConfiguration(configurationCode, portalCode, true)
            .then((response) => {
                // conf doesn't exist = POST
                if (Object.keys(response.data).length === 0) {
                    return this.postUserConfiguration(configuration, portalCode);
                }
                // conf exists = PUT
                return this.putUserConfiguration(configurationCode, configuration, portalCode, true);
            });
    }

    private static getURL(portalCode: string, configurationCode?: string): string {
        const url = ApiUrls.USER_CONFIGURATION.replace(":portalCode", portalCode);
        if (configurationCode) {
            return url.concat("/", configurationCode);
        }

        return url;
    }

    public static storeCurrentContractConfiguration = (contract: UserContract, callback: any = () => {}): void => {
        const configData: UserConfiguration = {
            portal: Constants.PORTAL_NAME as string,
            code: Constants.PORTAL_CONFIGURATION_CODE as string,
            data: {'current_contract': contract}
        }
        this.saveUserConfiguration(Constants.PORTAL_CONFIGURATION_CODE, configData)
            .then(callback)
    }

    // Get current contract in configuration or undefined is no contract defined
    public static async getCurrentConfigurationContract(): Promise<UserContract|undefined> {
        let responseConfiguration = await UserConfigurationService.getUserConfiguration(Constants.PORTAL_CONFIGURATION_CODE, Constants.PORTAL_NAME)

        return responseConfiguration.data?.data ? JSON.parse(responseConfiguration.data.data)?.current_contract : undefined
    }
}
