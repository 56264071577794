import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { SzTab } from "@suezenv/react-theme-components";
import { Area, Contract } from "../../../types";
import AdminExploitants from "../exploitants";
import AdminAreas from "../areas";
import AdminAreaCategories from "../areas/areaCategories";
import AdminAreaOperators from "../areas/areaOperators";
import AdminAreaOrganizations from "../areas/areaOrganizations";
import AdminOrganizations from "../organizations";
import AdminIndustrialSites from "../industrialSite/SitesList";
import AdminUsers from '../users';
import AdminServices from "../services";
import './adminTabs.scss';
import AdminOrganizationUser from "../organizations/organizationUsers";
import AdminOrganizationAreas from "../organizations/organizationAreas";
import AdminOrganizationServices from "../organizations/organizationServices";
import AdminUserAreas from "../users/userAreas";
import AdminUserContracts from "../users/AdminUserContracts";
import AdminUserOrganizations from "../users/userOrganizations";
import AdminUserOperators from "../users/userOperators";
import { adminTabsEventKeys } from "../../../constants";

type AdminTab = {
    eventKey: string,
    title: string,
    component: JSX.Element
}

type AdminTabsProps = {
    area?: Area,
    contractSelected?: Contract,
    contractId?: string,
    organizationId?: string,
    tabsDisplayed?: string[]
    userId?: string
    readonly?: boolean,
    children?: any
}

const AdminTabs = (props: AdminTabsProps) => {
    const { area, contractSelected, organizationId, tabsDisplayed, contractId, userId, readonly, children } = props;
    const history = useHistory();
    const { hash } = useLocation();
    const { t } = useTranslation();

    const defaultTabs: AdminTab[] = [
        {
            eventKey: adminTabsEventKeys.AREA_ORGANIZATIONS,
            title: t('admin.tabs.organizations'),
            component: area ? <AdminAreaOrganizations readonly={readonly} contractSelected={contractSelected} area={area}/> : <></>
        },
        {
            eventKey: adminTabsEventKeys.AREA_OPERATORS,
            title: t('admin.tabs.areaOperators'),
            component: area ? <AdminAreaOperators readonly={readonly} contractSelected={contractSelected} area={area}/> : <></>
        },
        {
            eventKey: adminTabsEventKeys.AREA_SKILLS,
            title: t('admin.tabs.areaCategories'),
            component: area ? <AdminAreaCategories contractSelected={contractSelected} area={area}/> : <></>
        },
        {
            eventKey: adminTabsEventKeys.CONTRACT_AREA,
            title: t('admin.tabs.areas'),
            component: <AdminAreas readonly={readonly} contractSelected={contractSelected} />
        },
        {
            eventKey: adminTabsEventKeys.CONTRACT_USERS,
            title: t('admin.tabs.users'),
            component: <AdminUsers readonly={readonly} contractSelected={contractSelected}/>
        },
        {
            eventKey: adminTabsEventKeys.CONTRACT_INDUSTRIAL_SITES,
            title: t('admin.tabs.industrialSites'),
            component: <AdminIndustrialSites contractSelected={contractSelected} />
        },
        {
            eventKey: adminTabsEventKeys.CONTRACT_ORGANIZATIONS,
            title: t('admin.tabs.organizations'),
            component: <AdminOrganizations readonly={readonly} contractSelected={contractSelected}/>
        },
        {
            eventKey: adminTabsEventKeys.CONTRACT_OPERATOR,
            title: t('admin.tabs.exploitants'),
            component: <AdminExploitants readonly={readonly} contractSelected={contractSelected}/>
        },
        {
            eventKey: adminTabsEventKeys.CONTRACT_SERVICES,
            title: t('admin.tabs.services'),
            component: <AdminServices readonly={readonly} contractSelected={contractSelected}/>
        },
        {
            eventKey: adminTabsEventKeys.ORGANIZATION_USERS,
            title: t('admin.tabs.users'),
            component: organizationId && contractId ? <AdminOrganizationUser readonly={readonly} contractId={contractId} organizationId={organizationId}/> : <></>
        },
        {
            eventKey: adminTabsEventKeys.ORGANIZATION_AREAS,
            title: t('admin.tabs.areas'),
            component: organizationId ? <AdminOrganizationAreas readonly={readonly} organizationId={organizationId}/> : <></>
        },
        {
            eventKey: adminTabsEventKeys.ORGANIZATION_SERVICES,
            title: t('admin.tabs.services'),
            component: organizationId ? <AdminOrganizationServices readonly={readonly} organizationId={organizationId}/> : <></>
        },
        {
            eventKey: adminTabsEventKeys.USER_ORGANIZATIONS,
            title: t('admin.tabs.organizations'),
            component: userId ? <AdminUserOrganizations readonly={readonly} userId={userId} contractSelected={contractSelected}/> : <></>
        },
        {
            eventKey: adminTabsEventKeys.USER_OPERATORS,
            title: t('admin.tabs.exploitants'),
            component: userId ? <AdminUserOperators readonly={readonly} userId={userId} contractSelected={contractSelected}/> : <></>
        },
        {
            eventKey: adminTabsEventKeys.USER_CONTRACTS,
            title: t('admin.tabs.contracts'),
            component: userId ? <AdminUserContracts /> : <></>
        },
        {
            eventKey: adminTabsEventKeys.USER_AREAS,
            title: t('admin.tabs.areas'),
            component: userId ? <AdminUserAreas readonly={readonly} userId={userId} contractSelected={contractSelected}/> : <></>
        },
    ];

    const [tab, setTab] = useState<AdminTab>();
    const [tabs, setTabs] = useState(defaultTabs);

    const filterDisplayedTabs = (tabsDisplayed: string[]) => {
        const tabsFiltered = tabs.filter((tab: AdminTab) => tabsDisplayed.includes(tab.eventKey));
        setTabs(tabsFiltered);
    };
    const filterTabsByKey = (filter: string) => {
        return tabs.filter((tab: AdminTab) => tab.eventKey === filter)[0] || tabs[0]
    };
    const selectTabHandle = (newTab: string) => {
        const matchedTab = filterTabsByKey(newTab);
        setTab(matchedTab);
        history.push(`#${newTab}`);
    }

    useEffect(() => {
        if (tabsDisplayed) {
            filterDisplayedTabs(tabsDisplayed);
        }
        const defaultTab = filterTabsByKey(hash.replace('#', ''));
        setTab(defaultTab);
    }, [tabsDisplayed, area]);

    return <>
        <SzTab id="admin-tabs"
            activeKey={tab ? tab.eventKey : ''}
            selectTabHandle={selectTabHandle}
        >
            {tabs.map((tab: { eventKey: string, title: string, component: JSX.Element }) => {
                return <SzTab.SzTabContent
                    key={tab.eventKey}
                    eventKey={tab.eventKey}
                    title={tab.title}
                >
                    {tab.component}
                </SzTab.SzTabContent>
            })}
        </SzTab>
    {children}</>
};

export default AdminTabs;
