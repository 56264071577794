import axiosService from './Request';
import {AppUrls} from "../constants";

export class ThingsBoardService {
    public static getDashboards = () => {
        return axiosService.getWithParams(AppUrls.THINGS_BOARD_WORKSPACE, {}, false, true)
            .then(response => response.data);
    }
    public static getUserToken = () => {
        return axiosService.getWithParams(AppUrls.THINGS_BOARD_USER_TOKEN, {}, false, true)
            .then(response => response.data);
    }
}
