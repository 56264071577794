import { primaryLogoData, secondaryLogoData, primaryColorData, secondaryColorData } from '../actions';
import { CommonHelper } from "../helper/Common";
import { store } from '../store';
import { UserContract } from "../types";

export class ThemeService {
    public static setTheme(contract: UserContract) {
        const { contractMetadatas } = contract;
        const primaryLogo = CommonHelper.getMetadataByField(contractMetadatas, 'primaryLogo');
        const secondaryLogo = CommonHelper.getMetadataByField(contractMetadatas, 'secondaryLogo');
        const primaryColor = CommonHelper.getMetadataByField(contractMetadatas, 'primaryColor');
        const secondaryColor = CommonHelper.getMetadataByField(contractMetadatas, 'secondaryColor');

        if (primaryLogo) {
            store.dispatch(primaryLogoData(primaryLogo));
        }
        if (secondaryLogo) {
            store.dispatch(secondaryLogoData(secondaryLogo));
        }
        if (primaryColor) {
            store.dispatch(primaryColorData(primaryColor));
        }
        if (secondaryColor) {
            store.dispatch(secondaryColorData(secondaryColor));
        }
    }
}
