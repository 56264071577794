import React, { ChangeEvent, useEffect, useState } from "react";
import { SzCheckbox, SzDatePicker, SzInput, SzSelect, SzTypographie } from "@suezenv/react-theme-components";
import { useTranslation } from "react-i18next";
import { OrganizationTypes } from "../../../../constants";
import { LegalEntityService } from "../../../../services/LegalEntityService";
import { useQuery } from "react-query";
import { LegalEntity } from "../../../../types";

type PropsType = {
    contractId: string,
    organizationName: string,
    organizationType: string
    setOrganizationName: (s: string) => void
    legalEntity?: { value: string, label: string, text?: string },
    setLegalEntity?: (le: { value: string, label: string, text?: string }) => void,
    newOperatorLabel?: string,
    setNewOperatorLabel?: (label: string) => void,
    operatorContractId?: string,
    setOperatorContractId?: (id: string) => void,
    startDate?: Date,
    endDate?: Date,
    setStartDate?: (date: Date) => void,
    setEndDate?: (date: Date) => void
    setIsMandatoryFiledsCompleted: (b: boolean) => void
}

const Step1 = (props: PropsType) => {
    const {
        contractId,
        organizationType,
        organizationName,
        setOrganizationName,
        startDate,
        endDate,
        setStartDate,
        setEndDate,
        operatorContractId,
        setOperatorContractId,
        newOperatorLabel,
        setNewOperatorLabel,
        setLegalEntity,
        legalEntity,
        setIsMandatoryFiledsCompleted
    } = props;
    const { t } = useTranslation();

    const [newOrOldOperatorSwitch, setNewOrOldOperatorSwitch] = useState<boolean>(false)
    const [legalEntitiesOptions, setLegalEntitiesOptions] = useState<{ value: string; label: string; text?: string; }[]>([]);
    const [emptyNameError, setEmptyNameError] = useState<boolean>(false);
    const [emptyDateError, setEmptyDateError] = useState<boolean>(false);
    const [badDateError, setBadDateError] = useState<boolean>(false);

    let operatorAdditionalInputs: JSX.Element = <></>;

    const fetchLegalEntitiesOption = () => LegalEntityService.getContractLegalEntities(contractId, organizationType);

    const { data: legalEntities } = useQuery(["legalEntities", contractId, organizationType], fetchLegalEntitiesOption);

    useEffect(() => {
        if (legalEntities) {
            setLegalEntitiesOptions(legalEntities.data.map((legalEntity: LegalEntity) => {
                return { value: legalEntity.id, label: legalEntity.label }
            }))
        }
    }, [legalEntities]);

    const switchNewOldOperator = (e: ChangeEvent<HTMLInputElement>) => {
        setNewOrOldOperatorSwitch(e.target.checked);
        if (e.target.checked && setNewOperatorLabel) {
            setNewOperatorLabel("");
        }
        else if (setLegalEntity) {
            setLegalEntity({ value: "", label: "" });
        }
    }

    useEffect(() => {
        setEmptyNameError(false);
        setEmptyDateError(false);
        setBadDateError(false);
        setIsMandatoryFiledsCompleted(true);
        let hasError = false;
        if (!organizationName || organizationName === "") {
            setIsMandatoryFiledsCompleted(false);
            setEmptyNameError(true);
            hasError = true;
        }
        if (organizationType == OrganizationTypes.OPERATOR) {
            if (!startDate || !endDate) {
                setIsMandatoryFiledsCompleted(false);
                setEmptyDateError(true);
                hasError = true;
            }
            else if (startDate > endDate) {
                setIsMandatoryFiledsCompleted(false);
                setEmptyDateError(false);
                setBadDateError(true);
                hasError = true;
            }
        }
    }, [startDate, endDate, organizationName])

    if (organizationType == OrganizationTypes.OPERATOR && setOperatorContractId && setNewOperatorLabel && setNewOrOldOperatorSwitch && setStartDate && setEndDate) {
        operatorAdditionalInputs = <>
            <SzInput
                className={"mb-2"}
                value={operatorContractId ?? ""}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setOperatorContractId(e.target.value)}
                label={t(`admin.${organizationType}.modal.add.operatorContractId`)}
            />
            <SzCheckbox
                className={"mb-2"}
                label={t(`admin.${organizationType}.modal.add.oldOrNewLegalEntity`)}
                checked={newOrOldOperatorSwitch}
                id={"newOrOldOperator"}
                name={"newOrOldOperator"}
                type={"checkbox"}
                onChange={(e: any) => switchNewOldOperator(e)}
            />
            {newOrOldOperatorSwitch &&
            <div className={"w-100 mb-2"}>
                <SzTypographie
                    className={"mb-0 sz-form-group__label"}
                    color="inactive"
                    weight="bold"
                    tag="span"
                >
                    {t(`admin.${organizationType}.modal.add.oldLegalEntity`)}
                </SzTypographie>
                <SzSelect
                className={"mb-2"}
                id={'id-2'}
                options={legalEntitiesOptions}
                value={legalEntity}
                name={'input-2'}
                onChange={setLegalEntity}
                isSearchable
            /></div>
            }
            {!newOrOldOperatorSwitch &&
            <SzInput
                className={"mb-2"}
                value={newOperatorLabel}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setNewOperatorLabel(e.target.value)}
                label={t(`admin.${organizationType}.modal.add.legalEntityName`)}
            />
            }
            <SzTypographie
                className={"mb-0 sz-form-group__label"}
                color="inactive"
                weight="bold"
                tag="span"
            >
                {t(`admin.${organizationType}.modal.add.contractStartEndDate`)} *
            </SzTypographie>
            <SzDatePicker
                classNameStartDate={"pl-3"}
                classNameEndDate={"pr-3"}
                startDate={startDate}
                onChangeStartDate={setStartDate}
                endDate={endDate}
                onChangeEndDate={setEndDate}
                dateRange={true}
            />
            {emptyDateError && <SzTypographie variant={"caption"} color={"danger"}>{t('forms.error.empty_field')}</SzTypographie>}
            {badDateError && <SzTypographie variant={"caption"} color={"danger"}>{t('forms.error.bad_date_range')}</SzTypographie>}
        </>
    }


    return <div className={"d-flex flex-wrap w-100"}>
        <SzInput
            className={"mb-2"}
            value={organizationName}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setOrganizationName(e.target.value)}
            label={`${t(`admin.${organizationType}.modal.add.namePlaceholder`)} *`}
            required={true}
        />
        {emptyNameError && <SzTypographie variant={"caption"} color={"danger"}>{t('forms.error.empty_field')}</SzTypographie>}
        {operatorAdditionalInputs && organizationType == OrganizationTypes.OPERATOR && operatorAdditionalInputs}
    </div>
}

export default Step1;
