import React from "react";
import { SzTypographie } from "@suezenv/react-theme-components";
import { config } from "../../config";
import { useTranslation } from "react-i18next";

const LegalPolicies = () => {
    const { t } = useTranslation();

    return <div className={"p-5 h-100"}>
        <div className={"box scrollable multiline-translation"}>
            <SzTypographie variant={"h1"}>
                {t('legals.legalPolicies.title')}
            </SzTypographie>
            <SzTypographie variant={"h2"}>
                {t('legals.legalPolicies.p1Title')}
            </SzTypographie>
            <SzTypographie variant={"body"} weight={"light"}>
                {t('legals.legalPolicies.p1Content', { url: config.defaultUrl })}
            </SzTypographie>
            <SzTypographie variant={"h2"}>
                {t('legals.legalPolicies.p2Title')}
            </SzTypographie>
            <SzTypographie variant={"body"} weight={"light"}>
                {t('legals.legalPolicies.p2Content', { url: config.defaultUrl })}
            </SzTypographie>
            <SzTypographie variant={"h2"}>
                {t('legals.legalPolicies.p3Title')}
            </SzTypographie>
            <SzTypographie variant={"body"} weight={"light"}>
                {t('legals.legalPolicies.p3Content')}
            </SzTypographie>
        </div>
    </div>
}

export default LegalPolicies;
