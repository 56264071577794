import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { connect } from "react-redux";
import { SzAlert, SzIcon, SzPagination, SzSpinner, SzTable } from "@suezenv/react-theme-components";
import { Constants } from "../../../../constants";
import { Area, AreaCategory, Contract, Pagination } from "../../../../types";
import { AreaService } from "../../../../services/AreaService";
import TabHeader from "../../adminTabs/tabHeader"
import { CommonHelper } from "../../../../helper/Common";

type AreaCategoriesData = {
    label: string,
    actions: JSX.Element,
}

const AdminAreaCategories = (props: { area: Area, contractSelected?: Contract, currentContract: Contract }) => {
    const { area, contractSelected, currentContract } = props;
    const { t } = useTranslation();
    const [currentPage, setCurrentPage] = useState(1);
    const [areaCategoriesData, setAreaOrganizationsData] = useState<AreaCategoriesData[]>();
    const [pagination, setPagination] = useState<Pagination>();
    const usedContract = contractSelected ?? currentContract;

    const fetchAreaCategories = () => AreaService.getAreaCategories(usedContract.id, area.id, currentPage);
    const {
        data,
        isError,
        isLoading,
        isSuccess
    } = useQuery(['areaCategories', area.id, currentPage], fetchAreaCategories);


    useEffect(() => {
        if (isSuccess) {
            const categories: AreaCategory[] = data?.data;
            let areaCategoriesData: AreaCategoriesData[] = [];
            categories.forEach((areaCategory: AreaCategory) => areaCategoriesData.push(formatData(areaCategory)));
            setAreaOrganizationsData(areaCategoriesData);
            setPagination(CommonHelper.getPagination(categories.length, data?.headers));
        }
    }, [data]);

    const defaultSorted: any = [
        {
            dataField: 'name',
            order: 'desc'
        }
    ];

    const columns = [
        {
            dataField: 'label',
            text: t('admin.areaCategories.label')
        }
    ];

    const formatData = (areaCategory: AreaCategory): AreaCategoriesData => {
        return {
            label: areaCategory.label,
            actions: <SzIcon variant='line' icon='task-list-text-1' className="color-primary"/>
        }
    };

    const handlePageChange = (pageNumber: number) => setCurrentPage(pageNumber);

    const renderPagination = (pagination: Pagination) => {
        return <div className="d-flex justify-content-center my-4">
            <SzPagination
                totalItemsCount={pagination.totalCount}
                activePage={currentPage}
                onChange={handlePageChange}
                itemsCountPerPage={pagination.perPage}
                pageRangeDisplayed={+Constants.USERS_PAGINATION_PAGE_RANGE}
            />
        </div>;
    }

    if (isError) {
        return <SzAlert
            variant="warning"
            transparent={true}
            className="w-100 border-0 flex-column p-4"
        >
            {t('alert.loading_error')}
        </SzAlert>
    }

    if (isLoading) {
        return <div className='p-4 text-center'>
            <SzSpinner/>
        </div>
    }

    return <div className="box">
        <TabHeader title={t('admin.tabs.areaCategoriesTitle', { areaName: area.name, count: pagination?.totalCount })}/>
        {areaCategoriesData && <SzTable
            data={areaCategoriesData}
            keyField={'label'}
            columns={columns}
            defaultSorted={defaultSorted}
        />}
        {pagination && renderPagination(pagination)}
    </div>;
}

const mapStateToProps = (state: { contract: { currentContract: Contract } }) => ({
    currentContract: state.contract.currentContract
});

export default connect(mapStateToProps)(AdminAreaCategories);
