import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { SzAlert, SzIcon, SzPagination, SzSpinner, SzTable } from "@suezenv/react-theme-components";
import { AppUrls, Constants } from "../../../constants";
import { Contract, Pagination } from "../../../types";
import TabHeader from "../adminTabs/tabHeader";
import { IndustrialSiteService } from 'services/IndustrialSiteService';
import { IndustrialSite } from 'types/industrialSite';
import { CommonHelper } from 'helper/Common';

type IndustrialSiteData = {
    name: string;
}

const AdminIndustrialSitesList = (props: { currentContract: Contract, state: any, readonly?: boolean }) => {
    const history = useHistory();
    const { t } = useTranslation();
    const { readonly } = props;
    const [currentPage, setCurrentPage] = useState(Constants.DEFAULT_CURRENT_PAGE as number);
    const [industrialSiteData, setIndustrialSiteData] = useState<IndustrialSiteData[]>();
    const [pagination, setPagination] = useState<Pagination>();
    const [queryFilter, setQueryFilter] = useState<string>();

    const fetchIndustrialSites = () => {
        return IndustrialSiteService.getIndustrialSites(currentPage, undefined, queryFilter);
    };
    const {
        data,
        isError,
        isLoading,
        isSuccess
    } = useQuery(['industrialSites', currentPage, queryFilter], fetchIndustrialSites)

    const defaultSorted: any = [
        {
            dataField: 'name',
            order: 'desc'
        }
    ];

    const columns = [
        {
            dataField: 'name',
            text: t('admin.industrialSite.name')
        },
        {
            dataField: 'type',
            text: t('admin.industrialSite.type')
        },
        {
            dataField: 'endDateContract',
            text: t('admin.industrialSite.endDateContract')
        },
        {
            dataField: 'organizations',
            text: t('admin.industrialSite.organizations')
        }
    ];

    if (!readonly) {
        columns.push(
            {
                dataField: 'actions',
                text: ''
            })
    }

    const goToIndustrialSiteEdit = (siteId: string) => {
        history.push(AppUrls.ADMIN_GESTION_TERRITORIALE_INDUSTRIAL_SITE_EDIT.replace(':siteId', siteId))
    }

    const goToAddIndustrialSite = () => {
        history.push(AppUrls.ADMIN_GESTION_TERRITORIALE_INDUSTRIAL_SITE_CREATE)
    }

    const formatData = (industrialSite: IndustrialSite): any => {
        return {
            key: industrialSite.id,
            name: industrialSite.name,
            type: industrialSite.industrialSiteType.code,
            endDateContract: '-',
            organizations: industrialSite?.metadatas?.organizationsCount ?? 0,
            actions: <div className="text-right">
                <SzIcon variant='line' icon='task-list-text-1' className="color-primary"
                        onClick={() => goToIndustrialSiteEdit(industrialSite.id!)} />
            </div>
        }
    };

    const handlePageChange = (pageNumber: number) => setCurrentPage(pageNumber)

    const renderPagination = (pagination: Pagination) => {
        return <div className="d-flex justify-content-center my-4" >
            <SzPagination
                totalItemsCount={pagination.totalCount}
                activePage={currentPage}
                onChange={handlePageChange}
                itemsCountPerPage={pagination.perPage}
                pageRangeDisplayed={Constants.USERS_PAGINATION_PAGE_RANGE as number}
            />
        </div>
    }

    useEffect(() => {
        if (isSuccess) {
            const industrialSites = data?.data
            let industrialSiteData: any = []
            industrialSites.forEach((site: IndustrialSite) => { industrialSiteData.push(formatData(site)) }) 
            setIndustrialSiteData(industrialSiteData)
            setPagination(CommonHelper.getPagination(industrialSites.length, data?.headers));
        }
    }, [data]);

    if (isError) {
        return <SzAlert
            variant="warning"
            transparent={true}
            className="w-100 border-0 flex-column p-4"
        >
            {t('alert.loading_error')}
        </SzAlert>
    }

    return <div className="box">
        <TabHeader title={t('admin.tabs.industrialSiteTitle')}
                subtitle={isLoading && ' ' || t("admin.tabs.industrialSiteSubtitle", { count: pagination?.totalCount })}
                onSearch={setQueryFilter}
                onBtnClick={goToAddIndustrialSite}
        />

        {isLoading && <div className='p-4 text-center'>
                <SzSpinner/>
            </div>
        }

        {!isLoading && industrialSiteData && <SzTable
            data={industrialSiteData}
            keyField={'key'}
            columns={columns}
            defaultSorted={defaultSorted}
        />}

        {!isLoading && pagination && renderPagination(pagination)}

    </div>
}

const mapStateToProps = (state: { contract: { currentContract: Contract } }) => ({
    currentContract: state.contract.currentContract,
    state: state
});

export default connect(mapStateToProps)(AdminIndustrialSitesList);
