import { Constants } from "../constants";

/*
    Use like:
    const someMethod in your component = () => {
        let newSearch = {
            ...props.itv.search
        }
        
        // manipulate newSearch as you need

        props.refreshSearch(newSearch)
    }
*/
export const refreshInterventionSearch = (payload: object) => ({
    type: Constants.INTERVENTION_SEARCH,
    payload
});