import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {ApplicationConfigurationFormProps, RequestType} from "../../../../types";
import { useQuery } from "react-query";
import SzDualListBox, {SzDualListBoxOption} from "../../../elements/SzDualListBox";
import {SignalService} from "../../../../services/SignalService";
import {ApplicationConfigurationService} from "../../../../services/ApplicationConfigurationService";

type ApplicationParameters = {
    requestTypes: SzDualListBoxOption[]
};

const serviceName = "SIGNAL_NOTIFY";

export const SIGNAL_NOTIFY = (props: ApplicationConfigurationFormProps) => {
    const {contractId, serviceConfigurations, setServiceConfigurations, owner, ownerType} = props;
    const {t} = useTranslation();
    const [requestTypes, setRequestTypes] = useState<SzDualListBoxOption[]>("undefined" !== typeof serviceConfigurations[serviceName] ? serviceConfigurations[serviceName].parameters?.requestTypes ?? [] : []);
    const [requestTypesData, setRequestTypesData] = useState<[]>([]);
    const [options, setOptions] = useState<SzDualListBoxOption[]>([]);
    const [selectedOptions, setSelectedOptions] = useState<SzDualListBoxOption[]>([]);

    const onSelectedChange = (selected: SzDualListBoxOption[]) => {
        setSelectedOptions(selected);
    }

    const requestTypesDataToOption = (requestTypes: RequestType[]) => {
        return requestTypes.map((requestType: RequestType) => {
            return { id: requestType.id, label: requestType.label, code: requestType.id }
        })
    }

    const filterServiceOptions = (filter: string) => {
        if (requestTypesData) {
            setOptions(requestTypesDataToOption(requestTypesData).filter((option: SzDualListBoxOption) => option.label.toLowerCase().indexOf(filter.toLowerCase()) !== -1))
        }
    }

    const parameters = useRef<ApplicationParameters>({
        requestTypes: requestTypes
    });

    const fetchRequestTypes = () => {
        switch (ownerType) {
            case "CONTRACT":
                return SignalService.getRequestTypes();
            case "ORGANIZATION":
                return ApplicationConfigurationService.getByApplicationNameOwnerTypeOwnerId(
                    serviceName,
                    contractId,
                    "CONTRACT"
                );
        }
    }

    const {
        data: requestTypesResponse
    } = useQuery(['requestTypes', owner, ownerType], fetchRequestTypes);

    useEffect(() => {
        setRequestTypes(selectedOptions)
    }, [selectedOptions]);

    useEffect(() => {
        parameters.current = {...parameters.current, requestTypes}
        if ("undefined" === typeof serviceConfigurations[serviceName] ||
            "undefined" !== typeof serviceConfigurations[serviceName] && (
                parameters.current.requestTypes !== serviceConfigurations[serviceName].parameters?.btnLabel
            )) {
            setServiceConfigurations({
                ...serviceConfigurations, [serviceName]: {
                    displayButtonForceChildConfiguration: false,
                    name: serviceName,
                    owner: owner,
                    ownerType: ownerType,
                    parameters: {
                        requestTypes: parameters.current.requestTypes
                    }
                }
            });
        }
    }, [requestTypes]);

    useEffect(() => {
        if (requestTypesResponse) {
            switch (ownerType) {
                case "CONTRACT":
                    setRequestTypesData(requestTypesResponse.data.map((requestType: any) => {
                        return {id: requestType.id, label: requestType.label}
                    }))
                    break;
                case "ORGANIZATION":
                    setRequestTypesData(requestTypesResponse.data.parameters.requestTypes.map((requestType: any) => {
                        return {id: requestType.id, label: requestType.label}
                    }))
                    break;
            }
        }

    }, [requestTypesResponse]);

    useEffect(() => {
        if (requestTypesData) {
            setOptions(requestTypesData)
        }
    }, [requestTypesData]);

    return <div>
        <SzDualListBox
            options={options}
            selectedOptions={selectedOptions}
            showRowIcons={true}
            onlySelectWithRowIcons={true}
            leftSideTitle={t("admin.services.modal.add.allRequestTypes")}
            rightSideTitle={t("admin.services.modal.add.selectedRequestTypes")}
            onChange={onSelectedChange}
            isScrollable={true}
            onKeywordsChange={filterServiceOptions}
            searchPlaceholder={t('search')}
        />
    </div>

}
