import { UserState, Widget } from "../types";
import { RightsHelper } from "../helper/RightsHelper";
import serviceWidgetMapping from "../constants/serviceWidgetMapping";
import widgetsConf from "../constants/widgetsConf";
import { useSelector } from "react-redux";

export function useUserWidgets() {

        let userWidgets: Widget[] = [];

        const user = useSelector((state: {user: UserState}) => state.user);

        // Mix widgetsConf and serviceWidgetMapping
        widgetsConf.forEach(widgetConf => {
            // Get service widget mapping:
            const widgetMapping = serviceWidgetMapping[widgetConf.type];

            // Is user granted?
            if (RightsHelper.isGranted(user, widgetMapping.roles)) {
                let userWidget: Widget = { ...widgetConf, ...widgetMapping };
                userWidgets.push(userWidget);
            }
        });

        return userWidgets;
}
