import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import {
    SzAlert,
    SzIcon, SzModal,
    SzPagination,
    SzSpinner,
    SzTable,
    SzTypographie
} from "@suezenv/react-theme-components";
import { Pagination, Organization, Contract } from "../../../../types";
import { OrganizationService } from "../../../../services/OrganizationService";
import TabHeader from "../../adminTabs/tabHeader";
import { AppAdminDetailsUrls, Constants, OrganizationTypes } from "../../../../constants";
import { CommonHelper } from "../../../../helper/Common";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { displayMessageIfEmptyTab } from 'components/admin/adminTabs/common';

type UserOrganizationsData = {
    label: JSX.Element,
    areas: string,
    categories: string,
    contractEnd: string
    actions: JSX.Element
}

type AdminUserOperators = {
    userId: string,
    contractSelected?: Contract,
    currentContract: Contract,
    readonly?: boolean
}

const AdminUserOperators = (props: AdminUserOperators) => {
    const history = useHistory();
    const location = useLocation();
    const { t } = useTranslation();
    const { userId, currentContract, contractSelected, readonly } = props;
    const usedContract = contractSelected ?? currentContract;
    const [showAddModal, setShowAddModal] = useState(false);
    const [userOrganizationsData, setUserOrganizationsData] = useState<UserOrganizationsData[]>();
    const [pagination, setPagination] = useState<Pagination>();
    const [currentPage, setCurrentPage] = useState(1);

    const fetchOrganizationByUser = () => OrganizationService.getOrganizationsByUserId(userId, OrganizationTypes.OPERATOR, currentPage, Constants.DEFAULT_PER_PAGE, usedContract.id);
    const {
        data: userOrganizations,
        isError,
        isLoading,
        isSuccess
    } = useQuery(['userOrganizations', userId, currentPage], fetchOrganizationByUser);

    useEffect(() => {
        if (isSuccess) {
            const organizations: Organization[] = userOrganizations?.data;
            let userOrganizationsData: UserOrganizationsData[] = [];
            organizations.forEach((organization: Organization) => userOrganizationsData.push(formatData(organization)));
            setUserOrganizationsData(userOrganizationsData);
            setPagination(CommonHelper.getPagination(organizations.length, userOrganizations?.headers));
        }
    }, [userOrganizations]);

    const defaultSorted: any = [
        {
            dataField: 'name',
            order: 'desc'
        }
    ];

    const columns = [
        {
            dataField: 'label',
            text: t('admin.exploitant.label')
        },
        {
            dataField: 'categories',
            text: t('admin.exploitant.skillSet')
        },
        {
            dataField: 'contractEnd',
            text: t('admin.exploitant.endDate')
        },
        {
            dataField: 'areas',
            text: t('admin.exploitant.areas')
        }
    ];

    if (!readonly) {
        columns.push(
            {
                dataField: 'actions',
                text: ''
            })
    }

    const goToOrganizationDetails = (contractId: string, organizationId: string) => {
        history.push(CommonHelper.generateAdminDetailsSubRoute(location.pathname, AppAdminDetailsUrls.ORGANIZATION_DETAILS)
            .replace(':contractId', contractId)
            .replace(':organizationId', organizationId)
        );
    }

    const formatData = (organization: Organization): UserOrganizationsData => {
        const operatorContractEnd = CommonHelper.getMetadataByField(organization.organizationMetadatas, "operatorContractEnd");

        return {
            label:
                <SzTypographie weight="bold"
                               className="color-primary mb-0">{organization.label}</SzTypographie>
            ,
            areas: organization.area ? organization.area.length.toString() : '[NN]',
            categories: organization.category ? organization.category.length.toString() : '[NN]',
            contractEnd: operatorContractEnd ?
                CommonHelper.formatDate(operatorContractEnd, t('DATE_FORMAT')) : t('undefined'),
            actions: <SzIcon variant='line' icon='task-list-text-1' className="color-primary"
                             onClick={() => goToOrganizationDetails(usedContract.id, organization.id)}/>
        }
    };

    const handlePageChange = (pageNumber: number) => setCurrentPage(pageNumber);

    const renderPagination = (pagination: Pagination) => {
        return <div className="d-flex justify-content-center my-4">
            <SzPagination
                totalItemsCount={pagination.totalCount}
                activePage={currentPage}
                onChange={handlePageChange}
                itemsCountPerPage={pagination.perPage}
                pageRangeDisplayed={+Constants.USERS_PAGINATION_PAGE_RANGE}
            />
        </div>;
    }

    if (isError) {
        return <SzAlert
            variant="warning"
            transparent={true}
            className="w-100 border-0 flex-column p-4"
        >
            {t('alert.loading_error')}
        </SzAlert>
    }

    if (isLoading) {
        return <div className='p-4 text-center'>
            <SzSpinner/>
        </div>
    }

    return <div className="box">
        <TabHeader title={t('admin.tabs.exploitants')}
                   subtitle={t("admin.tabs.exploitantsSubtitle", { count: pagination?.totalCount })}
                   onBtnClick={ !readonly ? () => setShowAddModal(true) : undefined } />
        {userOrganizationsData && <SzTable
            data={userOrganizationsData}
            keyField={'label'}
            columns={columns}
            defaultSorted={defaultSorted}
        />}
        {displayMessageIfEmptyTab(userOrganizationsData, t)}
        <SzModal
            show={showAddModal}
            handleClose={() => setShowAddModal(false)}
            size={"xl"}
            centered={true}
            title={t('admin.Operator.modal.add.title')}
            children={[]}
        >
        </SzModal>
        {pagination && renderPagination(pagination)}
    </div>
}

const mapStateToProps = (state: { contract: { currentContract: Contract } }) => ({
    currentContract: state.contract.currentContract
});

export default connect(mapStateToProps)(AdminUserOperators);
