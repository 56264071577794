import { Constants } from "../constants";
import { ILoadingAction } from "../types";

export const loadingSpinner = (value: boolean): ILoadingAction => {
    return {
        type: Constants.DATA_LOADING,
        payload: {
            loading: value
        }
    }
}