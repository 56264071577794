import { Constants } from "../constants";

export const primaryLogoData = (primaryLogoData: any) => ({
    type: Constants.PRIMARY_LOGO,
    payload: primaryLogoData
});

export const secondaryLogoData = (secondaryLogoData: any) => ({
    type: Constants.SECONDARY_LOGO,
    payload: secondaryLogoData
});

export const primaryColorData = (primaryColorData: any) => ({
    type: Constants.PRIMARY_COLOR,
    payload: primaryColorData
});

export const secondaryColorData = (secondaryColorData: any) => ({
    type: Constants.SECONDARY_COLOR,
    payload: secondaryColorData
});
