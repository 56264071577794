import React from "react";
import { SzTypographie } from "@suezenv/react-theme-components";
import { useTranslation } from "react-i18next";

const Cookies = () => {

    const { t } = useTranslation();

    return <div className={"p-5 h-100"}>
        <div className={"box scrollable multiline-translation"}>
            <SzTypographie variant={"h1"}>
                {t('legals.Cookies.title')}
            </SzTypographie>
            <SzTypographie variant={"body"} weight={"light"} >
                <span dangerouslySetInnerHTML={
                    {__html: t('legals.Cookies.subtitle', {interpolation: {escapeValue: false}})}
                } />
            </SzTypographie>
            <SzTypographie variant={"h2"}>
                {t('legals.Cookies.p1Title')}
            </SzTypographie>
            <SzTypographie variant={"body"} weight={"light"}>
                <span dangerouslySetInnerHTML={
                    {__html: t('legals.Cookies.p1Content', {interpolation: {escapeValue: false}})}
                } />
            </SzTypographie>
            <SzTypographie variant={"h2"}>
                {t('legals.Cookies.p2Title')}
            </SzTypographie>
            <SzTypographie variant={"body"} weight={"light"}>
                <span dangerouslySetInnerHTML={
                    {__html: t('legals.Cookies.p2Content', {interpolation: {escapeValue: false}})}
                } />
            </SzTypographie>
            <SzTypographie variant={"h2"}>
                {t('legals.Cookies.p3Title')}
            </SzTypographie>
            <SzTypographie variant={"body"} weight={"light"}>
                <span dangerouslySetInnerHTML={
                    {__html: t('legals.Cookies.p3Content', {interpolation: {escapeValue: false}})}
                } />
            </SzTypographie>
            <SzTypographie variant={"h2"}>
                {t('legals.Cookies.p4Title')}
            </SzTypographie>
            <SzTypographie variant={"body"} weight={"light"}>
                {t('legals.Cookies.p4Content')}
            </SzTypographie>
            <SzTypographie variant={"h2"}>
                {t('legals.Cookies.p5Title')}
            </SzTypographie>
            <SzTypographie variant={"body"} weight={"light"}>
                {t('legals.Cookies.p5Content')}
            </SzTypographie>
            <SzTypographie variant={"h2"}>
                {t('legals.Cookies.p6Title')}
            </SzTypographie>
            <SzTypographie variant={"body"} weight={"light"}>
                <span dangerouslySetInnerHTML={
                    {__html: t('legals.Cookies.p6Content', {interpolation: {escapeValue: false}})}
                } />
            </SzTypographie>
            <SzTypographie variant={"body"}>
                <span dangerouslySetInnerHTML={
                    {__html: t('legals.Cookies.p7Title', {interpolation: {escapeValue: false}})}
                } />
            </SzTypographie>
            <SzTypographie variant={"body"} weight={"light"}>
                <span dangerouslySetInnerHTML={
                    {__html: t('legals.Cookies.p7Content', {interpolation: {escapeValue: false}})}
                } />
            </SzTypographie>
            <SzTypographie variant={"body"}>
                <span dangerouslySetInnerHTML={
                    {__html: t('legals.Cookies.p8Title', {interpolation: {escapeValue: false}})}
                } />
            </SzTypographie>
            <SzTypographie variant={"body"} weight={"light"}>
                <span dangerouslySetInnerHTML={
                    {__html: t('legals.Cookies.p8Content', {interpolation: {escapeValue: false}})}
                } />
            </SzTypographie>
        </div>
    </div>
}

export default Cookies;
