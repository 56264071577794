import React, {useEffect, useState} from 'react';
import '@suezenv/signal-widget/dist/index.css';
import 'leaflet/dist/leaflet.css';
import {useSelector} from "react-redux";
import {ThingsBoardService} from "../../services/ThingsBoardService";
import {config} from "../../config";
import { useQuery } from "react-query";
import {Dashboard} from "../../types";

const ThingsBoardDashboardPage = () => {
    const currentContract = useSelector((state: any) => state.contract.currentContract)
    const [dashboardUri, setDashboardUri] = useState<string>(null);
    const [accessToken, setAccessToken] = useState<string>(null);

    const fetchUserToken = () => ThingsBoardService.getUserToken();
    const { data: userToken } = useQuery<Dashboard[]>([], fetchUserToken);
    const fetchDashboards = () => ThingsBoardService.getDashboards();
    const { data: dashboards } = useQuery<Dashboard[]>([], fetchDashboards);

    useEffect(() => {
        if (dashboards) {
            if (dashboards.length === 1) {
                setDashboardUri("/dashboardGroups/" + dashboards[0].dashboardGroup + "/" + dashboards[0].id.id)
            } else {
                setDashboardUri("/dashboardGroups/")
            }
        }
    }, [dashboards]);

    useEffect(() => {
        if (userToken) {
            setAccessToken(userToken.token);
        }
    }, [userToken]);

    return (
        <div className={'row'}>
            <div className={'col-12'}>
                {dashboardUri &&
                    <iframe
                        src={config.portalThingsboardUrl + dashboardUri + "?accessToken=" + accessToken}
                        scrolling={"no"}
                        allowFullScreen={true}
                        style={{height: '95vh', width: '195vh'}}
                    />
                }
            </div>
        </div>
    );
}

export default ThingsBoardDashboardPage;
