import { UserState } from "../types";
import { UserType } from "@suezenv/public-space-planner-widget/dist/types/user.type";
import { ApiUrls } from "../constants";
import axiosService from "./Request";

export class PublicSpacePlannerService {
    public static transTypeUser = (user: UserState): UserType => {
        return {
            isLogged: user.logged,
            data: {
                id: user.info.heimdallId as string,
                email: user.info.email,
                firstName: user.info.firstName,
                lastName: user.info.lastName,
                roles: user.info.roles,
                services: user.info.services,
                organization: {
                    id: user.info.organization,
                    label: '',
                    requestTypeReferences: [],
                },
            },
        };
    }

    public static getWidgetStats = (filters: string) => {
        const body = {
            "aggregateBy" : "currentStatus",
            "statuses" : "new,coming,inProgress",
            "withPagination" : 0
        }

        return axiosService.getWithParams(ApiUrls.PUBLIC_SPACE_PLANNER_STATS, body, false, true);
    }
}
