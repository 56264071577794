import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useQuery, useQueryClient } from "react-query";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { SzAlert, SzIcon, SzModal, SzPagination, SzSpinner, SzTable } from "@suezenv/react-theme-components";
import { AppAdminDetailsUrls, Constants, OrganizationTypes } from "../../../constants";
import { Contract, Organization, Pagination } from "../../../types";
import { CommonHelper } from "../../../helper/Common";
import { OrganizationService } from "../../../services/OrganizationService";
import TabHeader from "../adminTabs/tabHeader";
import AdminOrganizationAdd from "../organizations/AdminOrganizationAdd";
import ConfirmationModal from "../../elements/ConfirmationModal";

const AdminExploitants = (props: { contractSelected?: Contract, currentContract: Contract, readonly?: boolean }) => {
    type ExploitantData = {
        id: string,
        actions: JSX.Element,
        endDate: string,
        members: string | JSX.Element,
        label: string,
        areas: number,
        skillSet: number,
    }

    const { currentContract, contractSelected, readonly } = props;
    const usedContract = contractSelected ?? currentContract;
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();
    const [exploitantsData, setExploitantsData] = useState<ExploitantData[]>();
    const queryClient = useQueryClient();
    const [showAddModal, setShowAddModal] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pagination, setPagination] = useState<Pagination>();
    const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
    const [organizationToDeleteId, setOrganizationToDeleteId] = useState<string>("");
    const [organizationToDeleteName, setOrganizationToDeleteName] = useState<string>("");
    const [queryFilter, setQueryFilter] = useState<string>("");

    const refreshOperators = () => {
        queryClient.invalidateQueries(['operators', usedContract.id]);
    }

    const fetchExploitants = () => OrganizationService.getOrganizations(usedContract.id, OrganizationTypes.OPERATOR, queryFilter, currentPage);

    const {
        data,
        isError,
        isLoading,
        isSuccess
    } = useQuery(['operators', usedContract.id, queryFilter, currentPage], fetchExploitants);

    const fetchOrganizationsNbUsers = () => OrganizationService.getOrganizationNbUsers(data?.data.map((org: Organization) => org.id), usedContract.id);

    const {
        data: nbUsers,
        isError: nbUsersError
    } = useQuery(["organizationsNbUsers", usedContract.id, queryFilter, currentPage], fetchOrganizationsNbUsers, { enabled: isSuccess && !isLoading });

    useEffect(() => {
        if (isSuccess) {
            const exploitants: Organization[] = data?.data

            let ExploitantsData: ExploitantData[] = [];
            exploitants.forEach((exploitant: Organization) => ExploitantsData.push(formatData(exploitant)));
            setExploitantsData(ExploitantsData);
            setPagination(CommonHelper.getPagination(exploitants.length, data?.headers));
        }
    }, [data])

    useEffect(() => {
        if (exploitantsData) {
            let tmpOrganizationData = exploitantsData?.slice(0);
            tmpOrganizationData = tmpOrganizationData.map((orgData: ExploitantData) => {
                return {
                    ...orgData,
                    members: nbUsers?.data ? CommonHelper.findEntityCount(Object.entries(nbUsers?.data), nbUsersError, orgData.id) :
                        <SzSpinner/>
                };
            });
            setExploitantsData(tmpOrganizationData);
        }
    }, [nbUsers, nbUsersError]);

    const defaultSorted: any = [
        {
            dataField: 'name',
            order: 'desc'
        }
    ];

    const columns = [
        {
            dataField: 'label',
            text: t('admin.exploitant.name')
        },
        {
            dataField: 'skillSet',
            text: t('admin.exploitant.skillSet')
        },
        {
            dataField: 'endDate',
            text: t('admin.exploitant.endDate')
        },
        {
            dataField: 'areas',
            text: t('admin.exploitant.areas')
        },
        {
            dataField: 'members',
            text: t('admin.exploitant.members')
        }
    ];

    if (!readonly) {
        columns.push(
            {
                dataField: 'actions',
                text: ''
            })
    }

    const deleteOrganization = () => {
        setShowConfirmationModal(false);
        OrganizationService.deleteOrganization(organizationToDeleteId)
            .then(refreshOperators)
            .then(hideDeleteOrganizationConfirmationModal);
    }

    const showDeleteConfirmationModal = (organization: Organization) => {
        setOrganizationToDeleteId(organization.id);
        setOrganizationToDeleteName(organization.label);
        setShowConfirmationModal(true);
    }

    const hideDeleteOrganizationConfirmationModal = () => {
        setShowConfirmationModal(false);
        setOrganizationToDeleteId("");
        setOrganizationToDeleteName("");
    }

    const goToOrganizationDetails = (contractId: string, organizationId: string) => {
        history.push(CommonHelper.generateAdminDetailsSubRoute(location.pathname, AppAdminDetailsUrls.OPERATOR_DETAILS)
            .replace(':contractId', contractId)
            .replace(':operatorId', organizationId)
        );
    }

    const formatData = (exploitant: Organization): ExploitantData => {
        const operatorContractEnd = CommonHelper.getMetadataByField(exploitant.organizationMetadatas, "operatorContractEnd");

        return {
            id: exploitant.id,
            label: exploitant.label,
            areas: exploitant.area.length,
            endDate: operatorContractEnd ? CommonHelper.formatDate(operatorContractEnd, t('DATE_FORMAT')): t('undefined'),
            skillSet: exploitant.category.length,
            members: nbUsers?.data ? CommonHelper.findEntityCount(Object.entries(nbUsers?.data), nbUsersError, exploitant.id) :
                <SzSpinner/>,
            actions: <div className={'text-right'}>
                <SzIcon variant='line' icon='task-list-text-1' className="color-primary pr-3"
                        onClick={() => goToOrganizationDetails(usedContract.id, exploitant.id)}
                />
                <SzIcon variant='line' icon='bin-2-alternate' className="color-danger pr-5"
                        onClick={() => showDeleteConfirmationModal(exploitant)}
                />
            </div>
        };
    };

    const handlePageChange = (pageNumber: number) => setCurrentPage(pageNumber);

    const renderPagination = (pagination: Pagination) => {
        return <div className="d-flex justify-content-center my-4">
            <SzPagination
                totalItemsCount={pagination.totalCount}
                activePage={currentPage}
                onChange={handlePageChange}
                itemsCountPerPage={pagination.perPage}
                pageRangeDisplayed={+Constants.USERS_PAGINATION_PAGE_RANGE}
            />
        </div>;
    }

    if (isError) {
        return <SzAlert
            variant="warning"
            transparent={true}
            className="w-100 border-0 flex-column p-4"
        >
            {t('alert.loading_error')}
        </SzAlert>;
    }

    return <div className="box">
        <TabHeader title={t('admin.tabs.exploitants')}
                   subtitle={t("admin.tabs.exploitantsSubtitle", { count: pagination?.totalCount })}
                   onBtnClick={ !readonly ? () => setShowAddModal(true) : undefined}
                   onSearch={setQueryFilter}
        />
        {isLoading && <div className='p-4 text-center'>
            <SzSpinner/>
        </div>
        }
        {!isLoading && exploitantsData && <SzTable
            data={exploitantsData}
            keyField={'label'}
            columns={columns}
            defaultSorted={defaultSorted}
        />}
        <SzModal
            show={showAddModal}
            handleClose={() => setShowAddModal(false)}
            size={"xl"}
            centered={true}
            title={t('admin.Operator.modal.add.title')}
        >
            <AdminOrganizationAdd
                currentContract={usedContract}
                organizationType={OrganizationTypes.OPERATOR}
                refreshData={refreshOperators}
            />
        </SzModal>
        <ConfirmationModal
            show={showConfirmationModal}
            onValidate={deleteOrganization}
            onCancel={hideDeleteOrganizationConfirmationModal}
            displayCancelBtn={true}
            modalBody={t('admin.exploitant.modal.delete.body', {
                name: organizationToDeleteName
            })}
            modalTitle={t('admin.exploitant.modal.delete.title')}
        />
        {pagination && renderPagination(pagination)}
    </div>;
}
const mapStateToProps = (state: { contract: { currentContract: Contract } }) => ({
    currentContract: state.contract.currentContract
});

export default connect(mapStateToProps)(AdminExploitants);
