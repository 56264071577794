import React, { useState } from "react";
import { SzButton, SzModal, SzTypographie } from "@suezenv/react-theme-components";
import { useTranslation } from "react-i18next";

type PropsType = {
    show: boolean,
    onValidate: () => void,
    onCancel: () => void,
    displayCancelBtn: boolean,
    isDeletion?: boolean,
    modalTitle: string,
    modalBody?: string,
}

const ConfirmationModal = (props: PropsType) => {
    const { show, onValidate, onCancel, displayCancelBtn, isDeletion, modalBody, modalTitle} = props;
    const { t } = useTranslation();
    const [validating, setValidating] = useState<boolean>(false)

    const onValidateClick = () => {
        setValidating(true);
        onValidate();
        setValidating(false);
    }


    return <SzModal
        show={show}
        title={modalTitle}
        size={'lg'}
        handleClose={onCancel}
        centered={true}
        >
        <div>
            <SzTypographie>
                {modalBody ?? t('confirmationModalDefaultBody')}
            </SzTypographie>
        </div>
        <div className={"d-flex justify-content-end"}>
            <SzButton onClick={onValidateClick} className={isDeletion ? "bg-danger border-0" : ""} loader={validating} isDisabled={validating}>
                {t(isDeletion ? 'delete' : 'validate')}
            </SzButton>
            {displayCancelBtn && <SzButton onClick={onCancel} className={'ml-3'} variant={"secondary"}>
                {t('cancel')}
            </SzButton> }
        </div>
    </SzModal>
}

export default ConfirmationModal;
