import { UserState } from "../types";
import { UserType } from "@suezenv/signal-widget/dist/signal-app/type/user.type";
import axiosService from './Request';
import { ApiUrls, Constants } from "../constants";
import { useMemo } from "react";
export class SignalService {
    public static transTypeUser = (user: UserState): UserType => {
        return {
            isLogged: user.logged,
            data: {
                id: user.info.heimdallId as string,
                email: user.info.email,
                firstName: user.info.firstName,
                lastName: user.info.lastName,
                roles: user.info.roles,
                services: user.info.services,
                organization: {
                    id: user.info.organization,
                    label: '',
                    requestTypeReferences: [],
                },
            },
        };
    }

    public static getWidgetStats = (contractId: string) => {
        const body = {
            contract: contractId
        }
        return axiosService.getWithParams(ApiUrls.SIGNAL_STATS, body, false, true);
    }

    public static getTopCategory = () => {
        const url = ApiUrls.SIGNAL_TOP_CATEGORY.replace(':length', String(Constants.SIGNAL_TOP_CATEGORY_LENGTH));
        return axiosService.get(url, false, true);
    }

    public static getRequestTypes() {
        return axiosService.get(ApiUrls.REQUEST_TYPES, false, true);
    }
}



export const useUserHasService = (user: UserType, serviceType:string) => {
    const isHasSignalService = useMemo(() => {
        if (user.data) {
            return Object.keys(user.data.services).includes(serviceType);
        }
        return false;
    }, [user]);
    return isHasSignalService;
};