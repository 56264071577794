import React from "react";
import { SzTypographie } from "@suezenv/react-theme-components";
import { useTranslation } from "react-i18next";

const CGU = () => {

    const { t } = useTranslation();

    return <div className={"p-5 h-100"}>
        <div className={"box scrollable multiline-translation"}>
            <SzTypographie variant={"h1"}>
                {t('legals.CGU.title')}
            </SzTypographie>
            <SzTypographie variant={"body"} weight={"light"}>
                {t('legals.CGU.subtitle')}
            </SzTypographie>
            <SzTypographie variant={"h2"}>
                {t('legals.CGU.p1Title')}
            </SzTypographie>
            <SzTypographie variant={"body"} weight={"light"}>
                {t('legals.CGU.p1Content')}
            </SzTypographie>
            <SzTypographie variant={"h2"}>
                {t('legals.CGU.p2Title')}
            </SzTypographie>
            <SzTypographie variant={"body"} weight={"light"}>
                {t('legals.CGU.p2Content')}
            </SzTypographie>
            <SzTypographie variant={"h2"}>
                {t('legals.CGU.p3Title')}
            </SzTypographie>
            <SzTypographie variant={"body"} weight={"light"}>
                {t('legals.CGU.p3Content')}
            </SzTypographie>
            <SzTypographie variant={"h2"}>
                {t('legals.CGU.p4Title')}
            </SzTypographie>
            <SzTypographie variant={"body"} weight={"light"}>
                {t('legals.CGU.p4Content')}
            </SzTypographie>
            <SzTypographie variant={"h2"}>
                {t('legals.CGU.p5Title')}
            </SzTypographie>
            <SzTypographie variant={"body"} weight={"light"}>
                {t('legals.CGU.p5Content')}
            </SzTypographie>
            <SzTypographie variant={"h2"}>
                {t('legals.CGU.p6Title')}
            </SzTypographie>
            <SzTypographie variant={"body"} weight={"light"}>
                {t('legals.CGU.p6Content')}
            </SzTypographie>
            <SzTypographie variant={"h2"}>
                {t('legals.CGU.p7Title')}
            </SzTypographie>
            <SzTypographie variant={"body"} weight={"light"}>
                {t('legals.CGU.p7Content')}
            </SzTypographie>
            <SzTypographie variant={"h2"}>
                {t('legals.CGU.p8Title')}
            </SzTypographie>
            <SzTypographie variant={"body"} weight={"light"}>
                {t('legals.CGU.p8Content')}
            </SzTypographie>
            <SzTypographie variant={"h2"}>
                {t('legals.CGU.p9Title')}
            </SzTypographie>
            <SzTypographie variant={"body"} weight={"light"}>
                {t('legals.CGU.p9Content')}
            </SzTypographie>
            <SzTypographie variant={"h2"}>
                {t('legals.CGU.p10Title')}
            </SzTypographie>
            <SzTypographie variant={"body"} weight={"light"}>
                {t('legals.CGU.p10Content')}
            </SzTypographie>
            <SzTypographie variant={"h2"}>
                {t('legals.CGU.p11Title')}
            </SzTypographie>
            <SzTypographie variant={"body"} weight={"light"}>
                {t('legals.CGU.p11Content')}
            </SzTypographie>
            <SzTypographie variant={"h2"}>
                {t('legals.CGU.p12Title')}
            </SzTypographie>
            <SzTypographie variant={"body"} weight={"light"}>
                {t('legals.CGU.p12Content')}
            </SzTypographie>
            <SzTypographie variant={"h2"}>
                {t('legals.CGU.p13Title')}
            </SzTypographie>
            <SzTypographie variant={"body"} weight={"light"}>
                {t('legals.CGU.p13Content')}
            </SzTypographie>
            <SzTypographie variant={"h2"}>
                {t('legals.CGU.p14Title')}
            </SzTypographie>
            <SzTypographie variant={"body"} weight={"light"}>
                {t('legals.CGU.p14Content')}
            </SzTypographie>
        </div>
    </div>
}

export default CGU;
