import { Constants, VdmStatusMap, StatusMap, Interventions } from '../constants';
import moment from 'moment';

const itvStartDate = moment().format('YYYY-MM-DD'); // today
const itvEndDate = moment().add(1, 'day').format('YYYY-MM-DD'); // tomorrow

const status = [
    StatusMap.DONE,
    StatusMap.ONGOING,
    StatusMap.PLANNED,
];

const initialState = {
    search: {
        status: status,
        startDate: itvStartDate,
        endDate: itvEndDate,
        address: null,
        itemsPerPage: Interventions.ITV_COUNT_PER_PAGE,
        outcome: {
            waterSupplyStopped: true,
            streetClosed: true
        }
    },
};

const interventionReducers = (state:any = initialState, action:any) => {
    switch (action.type) {
        case Constants.INTERVENTION_SEARCH:
            return {
                ...state,
                search: {
                    ...action.payload
                }
            };
        default:
            return state;
    }
};

export default interventionReducers;
