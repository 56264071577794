import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import FormSuccessPage from "../FormSuccessPage";
import successPicture from "../../../assets/img/contract/creation_success.svg";
import { SzButton, SzStepper } from "@suezenv/react-theme-components";
import Step1 from "./steps/Step1";
import Step2 from "./steps/Step2";
import { ApplicationConfiguration, Contract } from "../../../types";
import Step3 from "./steps/Step3";
import Step4 from "./steps/Step4";
import { default as StepApplicationConfiguration } from "../services/steps/step2";
import { OrganizationService } from "../../../services/OrganizationService";
import { ApplicationConfigurationService } from "../../../services/ApplicationConfigurationService";
import { useQuery } from "react-query";

const AdminOrganizationAdd = (props: { currentContract: Contract, organizationType: string, refreshData?: () => void }) => {
    const { currentContract, organizationType, refreshData } = props;
    const { t } = useTranslation();
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [startDate, setStartDate] = useState<Date | undefined>();
    const [endDate, setEndDate] = useState<Date | undefined>();
    const [operatorContractId, setOperatorContractId] = useState<string>("");
    const [newOperatorLabel, setNewOperatorLabel] = useState<string>("");
    const [legalEntity, setLegalEntity] = useState<{ value: string; label: string; text?: string; }>({
        value: "",
        label: ""
    });
    const [organizationName, setOrganizationName] = useState<string>("");
    const [areas, setAreas] = useState<string[]>([]);
    const [skills, setSkills] = useState<string[]>([]);
    const [contractServices, setContractServices] = useState<string[]>([]);
    const [selectedServices, setSelectedServices] = useState<string[]>([]);
    const [serviceConfigurations, setServiceConfigurations] = useState<{ [key: string]: ApplicationConfiguration }>({});
    const [parentConfs, setParentConfs] = useState<{ [serviceCode: string]: string }>({});
    const [isMandatoryFieldsCompleted, setIsMandatoryFieldsCompleted] = useState<boolean>(false);

    const fetchContractConfigurations = () => ApplicationConfigurationService.getByOwnerAndType(currentContract.id, "CONTRACT");
    const { data: contractConfigurations } = useQuery(['contractApplicationConfiguration', currentContract.id], fetchContractConfigurations)


    useEffect(() => {
        if (contractConfigurations?.data) {
            let configurations = parentConfs;
            contractConfigurations.data.map((conf: ApplicationConfiguration) => {
                    if (conf.id) {
                        configurations = { ...configurations, [conf.name]: conf.id }
                    }
                }
            )
            setParentConfs(configurations);
        }

    }, [contractConfigurations]);

    const [step, setStep] = useState(0);
    const stepperHeader = [
        t(`admin.${organizationType}.modal.add.name`),
        t(`admin.${organizationType}.modal.add.areas`),
        t(`admin.${organizationType}.modal.add.skillSets`),
        t(`admin.${organizationType}.modal.add.services`),
        t("admin.services.modal.add.serviceConfiguration")
    ]

    const onValidate = () => {
        const organizationData = {
            category: skills,
            area: areas,
            label: organizationName,
            type: organizationType,
            organizationContractService: contractServices,
            legalEntity: legalEntity.value,
            operatorContractId: operatorContractId,
            newOrganizationLabel: newOperatorLabel,
            startDate: startDate,
            endDate: endDate,
        }
        setIsSaving(true);
        OrganizationService.createOrganization(currentContract.id, organizationData)
            .then((response) => {
                const organizationId = response.data?.id ?? null;
                let promises: Promise<any>[] = [];
                if (serviceConfigurations && Object.keys(serviceConfigurations).length > 0) {
                    Object.values(serviceConfigurations).forEach((conf: ApplicationConfiguration) => {
                        conf.owner = organizationId;
                        if (conf.useParentConfiguration) {
                            conf.parentConfiguration = parentConfs[conf.name] ?? "";
                        }
                        promises.push(ApplicationConfigurationService.saveApplicationConfiguration(conf));
                    });
                }
                Promise.all(promises).then(() => {
                    if (refreshData) {
                        refreshData();
                    }
                    setIsSuccess(true);
                    setIsSaving(false);
                });
            });
    }

    const resetForm = () => {
        setOrganizationName("");
        setLegalEntity({ value: "", label: "" });
        setEndDate(new Date());
        setStartDate(new Date());
        setNewOperatorLabel("");
        setOperatorContractId("");
        setIsSaving(false);
        setIsSuccess(false);
        setStep(0);
    }

    const successComponent = <FormSuccessPage
        image={successPicture}
        title={t(`admin.${organizationType}.modal.add.success.title`)}
        subtitle={t(`admin.${organizationType}.modal.add.success.subtitle`)}
        middleBottomButtonIcon={"add-circle"}
        middleBottomButtonOnclick={resetForm}
        middleBottomButtonText={t(`admin.${organizationType}.modal.add.success.createNew`)}
    />

    const stepperBody = (children: JSX.Element, currentStep: number) => <div>
        <div className="col-12">
            {children}
        </div>
        <div className="col-12">
            <div className={"mt-2"}>
                <div className="col-md-2 offset-md-1 d-flex justify-content-start">
                    {currentStep > 0 &&
                        <SzButton
                            onClick={() => setStep(currentStep <= 0 ? 0 : currentStep - 1)}
                            variant={"secondary"}
                        >
                            {t('previous')}
                        </SzButton>
                    }
                </div>
                <div className="col-md-3 offset-md-5 d-flex justify-content-end">
                    {currentStep < 4 &&
                        <SzButton
                            onClick={() => setStep(currentStep + 1)}
                            variant={"primary"}
                            isDisabled={!isMandatoryFieldsCompleted}
                        >
                            {t('next')}
                        </SzButton>
                    }
                </div>
            </div>
        </div>
    </div>;

    const formStepper = <SzStepper
        header={stepperHeader}
        activeStep={step}
    >
        <SzStepper.SzHeader className="bg-transparent"/>
        <SzStepper.SzStep index={0}>
            {stepperBody(<Step1
                contractId={currentContract.id}
                organizationType={organizationType}
                organizationName={organizationName}
                setOrganizationName={setOrganizationName}
                operatorContractId={operatorContractId}
                setOperatorContractId={setOperatorContractId}
                newOperatorLabel={newOperatorLabel}
                setNewOperatorLabel={setNewOperatorLabel}
                legalEntity={legalEntity}
                setLegalEntity={setLegalEntity}
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                setIsMandatoryFiledsCompleted={setIsMandatoryFieldsCompleted}
            />, 0)}
        </SzStepper.SzStep>
        <SzStepper.SzStep index={1}>
            {stepperBody(<Step2
                contract={currentContract}
                areas={areas}
                setAreas={setAreas}
            />, 1)}
        </SzStepper.SzStep>
        <SzStepper.SzStep index={2}>
            {stepperBody(<Step3
                contract={currentContract}
                skillSets={skills}
                setSkillSets={setSkills}
            />, 2)}
        </SzStepper.SzStep>
        <SzStepper.SzStep index={3}>
            {stepperBody(<Step4
                contract={currentContract}
                setContractServices={setContractServices}
                contractServices={contractServices}
                setSelectedServices={setSelectedServices}
                skillSets={skills}
            />, 3)}
        </SzStepper.SzStep>
        <SzStepper.SzStep index={4}>
            <StepApplicationConfiguration
                contractId={currentContract.id}
                goToPrev={() => setStep(3)}
                isSaving={isSaving}
                onValidate={onValidate}
                ownerId={""}
                ownerType={"ORGANIZATION"}
                selectedServices={selectedServices}
                serviceConfigurations={serviceConfigurations}
                setServiceConfigurations={setServiceConfigurations}
                parentConfigurations={parentConfs}
            />
        </SzStepper.SzStep>
    </SzStepper>

    return isSuccess ? successComponent : formStepper;
}

export default AdminOrganizationAdd;
