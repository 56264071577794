import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { SzAlert, SzIcon, SzPagination, SzSpinner, SzTable } from "@suezenv/react-theme-components";
import { AppAdminDetailsUrls, Constants, OrganizationTypes } from "../../../../constants";
import { Area, Contract, Organization, Pagination } from "../../../../types";
import { AreaService } from "../../../../services/AreaService";
import TabHeader from "../../adminTabs/tabHeader";
import { CommonHelper } from "../../../../helper/Common";
import { useLocation } from "react-router-dom";

type AreaOrganizationsData = {
    actions: JSX.Element,
    label: string,
    areas: string,
    categories: string,
}

const AdminAreaOrganizations = (props: { area: Area, contractSelected?: Contract, currentContract: Contract, readonly?: boolean }) => {
    const { area, currentContract, contractSelected, readonly } = props;
    const history = useHistory();
    const location = useLocation();
    const { t } = useTranslation();
    const usedContract = contractSelected ?? currentContract;
    const [currentPage, setCurrentPage] = useState(1);
    const [areaOrganizationsData, setAreaOrganizationsData] = useState<AreaOrganizationsData[]>();
    const [pagination, setPagination] = useState<Pagination>();

    const fetchAreaOrganizations = () => AreaService.getAreaOrganizations(usedContract.id, area.id, OrganizationTypes.COMMUNITY, currentPage);
    const {
        data,
        isError,
        isLoading,
        isSuccess
    } = useQuery(['areaOrganizations', area.id, currentPage], fetchAreaOrganizations);


    useEffect(() => {
        if (isSuccess) {
            const organizations: Organization[] = data?.data;
            let areaOrganizationsData: AreaOrganizationsData[] = [];
            organizations.forEach((areaOrganization: Organization) => areaOrganizationsData.push(formatData(areaOrganization)));
            setAreaOrganizationsData(areaOrganizationsData);
            setPagination(CommonHelper.getPagination(organizations.length, data?.headers));
        }
    }, [data]);

    const defaultSorted: any = [
        {
            dataField: 'name',
            order: 'desc'
        }
    ];

    const columns = [
        {
            dataField: 'label',
            text: t('admin.areaOrganizations.label')
        },
        {
            dataField: 'areas',
            text: t('admin.areaOrganizations.areas')
        },
        {
            dataField: 'categories',
            text: t('admin.areaOrganizations.categories')
        }
    ];

    if (!readonly) {
        columns.push(
            {
                dataField: 'actions',
                text: ''
            })
    }

    const goToAreaOrganizationDetails = (contractId: string, areaOrganizationId: string) => {
        history.push(CommonHelper.generateAdminDetailsSubRoute(location.pathname, AppAdminDetailsUrls.ORGANIZATION_DETAILS)
            .replace(':contractId', contractId)
            .replace(':organizationId', areaOrganizationId)
        );
    }

    const formatData = (areaOrganization: Organization): AreaOrganizationsData => {
        return {
            label: areaOrganization.label,
            areas: areaOrganization.area ? areaOrganization.area.length.toString() : '[NN]',
            categories: areaOrganization.category ? areaOrganization.category.length.toString() : '[NN]',
            actions: <SzIcon variant='line' icon='task-list-text-1' className="color-primary"
                             onClick={() => goToAreaOrganizationDetails(usedContract.id, areaOrganization.id)}/>
        };
    };

    const handlePageChange = (pageNumber: number) => setCurrentPage(pageNumber);

    const renderPagination = (pagination: Pagination) => {
        return <div className="d-flex justify-content-center my-4">
            <SzPagination
                totalItemsCount={pagination.totalCount}
                activePage={currentPage}
                onChange={handlePageChange}
                itemsCountPerPage={pagination.perPage}
                pageRangeDisplayed={+Constants.USERS_PAGINATION_PAGE_RANGE}
            />
        </div>;
    }

    if (isError) {
        return <SzAlert
            variant="warning"
            transparent={true}
            className="w-100 border-0 flex-column p-4"
        >
            {t('alert.loading_error')}
        </SzAlert>;
    }

    if (isLoading) {
        return <div className='p-4 text-center'>
            <SzSpinner/>
        </div>;
    }

    return <div className="box">
        <TabHeader
            title={t('admin.tabs.areaOrganizationsTitle', { areaName: area.name, count: pagination?.totalCount })}/>
        {areaOrganizationsData && <SzTable
            data={areaOrganizationsData}
            keyField={'label'}
            columns={columns}
            defaultSorted={defaultSorted}
        />}
        {pagination && renderPagination(pagination)}
    </div>;
}

const mapStateToProps = (state: { contract: { currentContract: Contract } }) => ({
    currentContract: state.contract.currentContract
});

export default connect(mapStateToProps)(AdminAreaOrganizations);
