import React from "react";
import { connect } from "react-redux";
import AdminUserDetails from "../../components/admin/users/details";

const Profile = (props: { userId: string, contractId: string }) => {
    return <AdminUserDetails {...props}/>
}

const mapStateToProps = (state: any) => ({
    userId: state.user.info.heimdallId,
    contractId: state.contract.currentContract.id
});

export default connect(mapStateToProps)(Profile);
