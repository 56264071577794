import React from "react";
import { connect } from "react-redux";
import { Roles } from "../../constants";
import { Contract, UserState } from "../../types";
import { RightsHelper } from "../../helper/RightsHelper";
import LoadingPage from "../../pages/loadingPage";

const CurrentContractManager = (props: { children: any, currentContract: Contract, user: UserState }) => {
    const { currentContract, user } = props;
    const rights = [Roles.ROLE_SUPER_ADMIN_CONTRACT];

    if (!user.logged || RightsHelper.isGranted(user, rights) && !currentContract) {
        return <LoadingPage/>;
    }

    return props.children;
};

const mapStateToProps = (state: { contract: { currentContract: Contract }, user: UserState }) => ({
    currentContract: state.contract.currentContract,
    user: state.user
});

export default connect(mapStateToProps)(CurrentContractManager);
