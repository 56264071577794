import axiosService from './Request';
import { ApiUrls, Constants } from '../constants';

export class ContractServiceService {
    public static getAllByContract(contractId: string, currentPage?: number, itemsPerPage?: number, category?: string[], noCategory?: boolean) {
        const body = {
            currentPage: currentPage ?? 1,
            itemsPerPage: itemsPerPage ?? Constants.NOTIFICATIONS_PER_PAGE,
            category: category,
            noCategory: noCategory
        }
        const url = ApiUrls.CONTRACT_SERVICE.replace(":contractId", contractId);
        return axiosService.getWithParams(url, body, false, true)
            .then(response => response);
    }

    public static getContractServiceByName(contractId: string, currentPage?: number, serviceLabel?: string, itemsPerPage?: number, category?: string[], noCategory?: boolean) {
        const body = {
            currentPage: currentPage ?? 1,
            itemsPerPage: itemsPerPage ?? Constants.NOTIFICATIONS_PER_PAGE,
            category: category,
            noCategory: noCategory,
            serviceLabel: serviceLabel
        }

        const url = ApiUrls.CONTRACT_SERVICE.replace(":contractId", contractId);
        return axiosService.getWithParams(url, body, false, true)
            .then(response => response);
    }

    public static createContractServices(contractId: string, services: string[]) {
        const url = ApiUrls.CONTRACT_SERVICE.replace(":contractId", contractId)

        return axiosService.post(url, services);
    }

    public static deleteContractService(contractServiceId: string) {
        const url = ApiUrls.CONTRACT_SERVICE_DELETE.replace(':contractServiceId', contractServiceId);

        return axiosService.delete(url, [], false, true);
    }
}
