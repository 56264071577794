import axiosService from './Request';
import { ApiUrls, Constants } from '../constants';
import { AxiosResponse } from 'axios';
import { Contact, GeoZone, IndustrialSite } from 'types/industrialSite';
import {config} from "../config";

export class IndustrialSiteService {

    public static async getIndustrialSites(currentPage?: number, itemsPerPage?: number, q?: string): Promise<AxiosResponse<IndustrialSite[]>> {
        try {
            const url = `${ApiUrls.INDUSTRIAL_SITES_URL}`;
            const body = {
                currentPage: currentPage ?? Constants.DEFAULT_CURRENT_PAGE,
                itemsPerPage: itemsPerPage ?? Constants.DEFAULT_PER_PAGE,
                orderColumn: "name",
                q: q
            }
            const response = await axiosService.getWithParams(
                url,
                body
            );

            return response;
        } catch (error) {
            console.error("IndustrialSiteService | Failed to fetch industrial sites: ", error);

            throw error;
        }
    }

    public static async getOneIndustrialSite(siteId: string): Promise<AxiosResponse<IndustrialSite>> {
        try {
            const url = `${ApiUrls.INDUSTRIAL_SITE_URL}`.replace(':siteId', siteId);
            const response = await axiosService.get( url, false );

            this.fixNamingBug(response)

            return response;
        } catch (error) {
            console.error("IndustrialSiteService | Failed to fetch industrial site: " + siteId, error);

            throw error;
        }
    }

    // Send geo"j"son in back, need geo"J"son in front
    private static fixNamingBug(response: AxiosResponse) {
        response.data.geoZoneArea.geoJson = response.data.geoZoneArea.geojson
        delete response.data.geoZoneArea.geojson
    }

    public static async postIndustrialSites(industrialSite: IndustrialSite): Promise<AxiosResponse<IndustrialSite>> {
        try {
            const url = `${ApiUrls.INDUSTRIAL_SITES_URL}`;
            const response = await axiosService.post(
                url,
                industrialSite
            );

            return response;
        } catch (error) {
            console.error("IndustrialSiteService | Failed to POST Industrial site: ", error);

            throw error;
        }
    }

    public static async putIndustrialSites(industrialSite: IndustrialSite, siteId: string): Promise<AxiosResponse<IndustrialSite>> {
        try {
            const url = `${ApiUrls.INDUSTRIAL_SITE_URL}`.replace(':siteId', siteId)
            const response = await axiosService.put(
                url,
                industrialSite
            );

            return response;
        } catch (error) {
            console.error(`IndustrialSiteService | Failed to PUT Industrial site id ${siteId}: `, error);

            throw error;
        }
    }

    // CONTACTS //

    public static async getContact(siteId: string, contactId: string): Promise<AxiosResponse<Contact>> {
        try {
            const url = `${ApiUrls.INDUSTRIAL_SITE_CONTACT_EDIT_URL}`
                .replace(':siteId', siteId)
                .replace(':contactId', contactId)
            const response = await axiosService.get(url, false);

            return response;
        } catch (error) {
            console.error(`IndustrialSiteService | Failed to GET contact on id ${contactId}: `, error);

            throw error;
        }
    }

    public static async addNewContact(siteId: string, contact: Contact): Promise<boolean> {
        try {
            const url = `${ApiUrls.INDUSTRIAL_SITE_CONTACT_URL}`.replace(':siteId', siteId)
            const response = await axiosService.post(
                url,
                contact
            );

            return true
        } catch (error) {
            console.error(`IndustrialSiteService | Failed to POST contact: `, error);

            throw error;
        }
    }

    public static async updateContact(siteId: string, contactId: string, contact: Contact): Promise<boolean> {
        try {
            const url = `${ApiUrls.INDUSTRIAL_SITE_CONTACT_EDIT_URL}`
                .replace(':siteId', siteId)
                .replace(':contactId', contactId)
            const response = await axiosService.put(
                url,
                contact
            );

            return true
        } catch (error) {
            console.error(`IndustrialSiteService | Failed to POST contact: `, error);

            throw error;
        }
    }

    public static async deleteContact(siteId: string, contactId: string): Promise<boolean> {
        try {
            const url = `${ApiUrls.INDUSTRIAL_SITE_CONTACT_EDIT_URL}`
                .replace(':siteId', siteId)
                .replace(':contactId', contactId)
            const response = await axiosService.delete(url, {});

            return true
        } catch (error) {
            console.error(`IndustrialSiteService | Failed to DELETE contact id ${contactId}: `, error);

            throw error;
        }
    }

    // PHOTO //

    public static getPhotoSrc(photoId: string) {
        return config.apiUrl + ApiUrls.INDUSTRIAL_SITE_PHOTO_CONTENT_URL.replace(':photoId', photoId)
    }

    public static async postPhoto(siteId: string, attachment: any) {
        const url = ApiUrls.INDUSTRIAL_SITE_POST_PHOTO_URL.replace(':siteId', siteId)
       
        const data = new FormData();
        data.append("photo", attachment);

        try {
            return axiosService.post(url, data, false)
        } catch (error) {
            console.log(error)
        }

    }

    public static async deletePhoto(siteId: string, photoId: string) {
        const url = ApiUrls.INDUSTRIAL_SITE_PHOTO_URL
            .replace(':siteId', siteId)
            .replace(':photoId', photoId)
       
        try {
            return axiosService.delete(url, false)
        } catch (error) {
            console.log(error)
        }

    }
}
