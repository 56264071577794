import React, { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SzIcon, SzInput, SzTypographie } from "@suezenv/react-theme-components";
import classnames from "classnames";
import "./SzDualListBox.scss"

type PropsType = {
    options: SzDualListBoxOption[],
    selectedOptions: SzDualListBoxOption[],
    onKeywordsChange?: (s: string) => void,
    leftSideTitle: string,
    rightSideTitle: string,
    searchPlaceholder?: string,
    showRowIcons?: boolean,
    onlySelectWithRowIcons?: boolean,
    onChange: (selectedOptions: SzDualListBoxOption[]) => void,
    maxDisplayedOption?: number,
    optionCount?: number,
    showResultCount?: boolean,
    isScrollable?: boolean
}

export type SzDualListBoxOption = {
    id: string,
    label: string
    code: string
}

const SzDualListBox = (props: PropsType) => {
    const {
        options,
        selectedOptions,
        onKeywordsChange,
        onlySelectWithRowIcons,
        showRowIcons,
        rightSideTitle,
        leftSideTitle,
        searchPlaceholder,
        onChange,
        maxDisplayedOption,
        optionCount,
        showResultCount,
        isScrollable
    } = props;

    const { t } = useTranslation();

    const [localSelectedOptions, setLocalSelectedOptions] = useState<SzDualListBoxOption[]>(selectedOptions);
    const [keywords, setKeywords] = useState<string>("");

    const onLeftRowClick = (option: SzDualListBoxOption, onIcon: boolean = false) => {
        if (((!onlySelectWithRowIcons || (onlySelectWithRowIcons && !showRowIcons)) && !onIcon) || (onIcon && onlySelectWithRowIcons)) {
            setLocalSelectedOptions([...selectedOptions, option]);
        }
    }
    const onRightRowClick = (removedOption: SzDualListBoxOption, onIcon: boolean = false) => {
        if (((!onlySelectWithRowIcons || (onlySelectWithRowIcons && !showRowIcons)) && !onIcon) || (onIcon && onlySelectWithRowIcons)) {
            let tmpSelected = selectedOptions.slice(0);
            tmpSelected.splice(tmpSelected.findIndex((option: SzDualListBoxOption) => option.id === removedOption.id), 1);
            setLocalSelectedOptions(tmpSelected);
        }
    }

    useEffect(() => {
        onChange(localSelectedOptions);
    }, [localSelectedOptions]);

    useEffect(() => {
        setLocalSelectedOptions(selectedOptions);
    }, [selectedOptions])

    useEffect(() => {
        if (onKeywordsChange) {
            onKeywordsChange(keywords);
        }
    }, [keywords]);

    const filterSelectedOptionsFromOptions = (option: SzDualListBoxOption) => {
        return selectedOptions.find((selected: SzDualListBoxOption) => {
            return option.id === selected.id
        }) === undefined;
    }

    const limitDisplayedOptionsCount = (option: SzDualListBoxOption, index: number) => {
        if (maxDisplayedOption) {
            return index <= maxDisplayedOption - 1
        }

        return true;
    }

    const optionClassNames = classnames(
        'd-flex justify-content-between mb-2 p-2 box pl-4',
        !onlySelectWithRowIcons || (onlySelectWithRowIcons && !showRowIcons) ? 'cursor-pointer' : '',
    )

    const optionBoxClassNames = classnames(
        "option-box",
        isScrollable ? "scrollable" : ""
    );


    return <div className={"row sz-dual-list-box"}>
        <div className="col-md-6 p-2">
            <div className={"box box-dark"}>
                <div>
                    <SzTypographie weight={"bold"}>{leftSideTitle}</SzTypographie>
                    {onKeywordsChange &&
                        <div className={"d-flex"}>
                            <SzInput
                                placeholder={searchPlaceholder}
                                value={keywords}
                                onChange={(event: ChangeEvent<HTMLInputElement>) => setKeywords(event.target.value)}
                                handleClear={() => setKeywords("")}
                            />
                            <SzIcon icon={"search"} variant={"line"} className={"ml-3 pt-2"}/>
                        </div>
                    }
                </div>
                <div className={optionBoxClassNames}>
                    {options && options.filter(filterSelectedOptionsFromOptions).filter(limitDisplayedOptionsCount).map((option: SzDualListBoxOption, index: number) => {
                        return <div className={optionClassNames} onClick={() => onLeftRowClick(option)} key={index}>
                            <SzTypographie className={"m-0 mt-2"}>{option.label}</SzTypographie>
                            {showRowIcons &&
                                <SzIcon icon={"add-square"} variant={"line"}
                                        onClick={() => onLeftRowClick(option, true)}/>
                            }
                        </div>
                    })}
                </div>
            </div>
            {showResultCount &&
                <SzTypographie variant={"caption"} color={"disabled"}>
                    {t('resultCountOfMax', {
                        displayed: maxDisplayedOption ? Math.min(maxDisplayedOption, options.length) : options.length,
                        total: optionCount ? Math.max(options.length, optionCount) : options.length
                    })}
                </SzTypographie>
            }
        </div>
        <div className="col-md-6 p-2">
            <div className={"box box-dark"}>
                <div className={onKeywordsChange ? "pb-icon-size" : ""}>
                    <SzTypographie weight={"bold"}>{rightSideTitle}</SzTypographie>
                </div>
                <div className={optionBoxClassNames}>
                    {localSelectedOptions.map((option: SzDualListBoxOption, index: number) => {
                        return <div key={option.id} className={optionClassNames} onClick={() => onRightRowClick(option)}>
                            <SzTypographie className={"m-0 mt-2"}>{option.label}</SzTypographie>
                            {showRowIcons &&
                                <SzIcon icon={"remove-square"} variant={"line"}
                                        onClick={() => onRightRowClick(option, true)}/>
                            }
                        </div>
                    })}
                </div>
            </div>
        </div>
    </div>
}

export default SzDualListBox