import SzDualListBox, { SzDualListBoxOption } from "../../../elements/SzDualListBox";
import React, { useEffect, useState } from "react";
import { ServiceService } from "../../../../services/ServiceService";
import { ContractServiceService } from "../../../../services/ContractServiceService";
import { useQuery } from "react-query";
import { ContractService, Service } from "../../../../types";
import { useTranslation } from "react-i18next";
import { SzButton } from "@suezenv/react-theme-components";

const Step1 = (props: { setSelectedServices: (services: string[]) => void, contractId: string , goToNext: () => void}) => {
    const { setSelectedServices, contractId, goToNext } = props;
    const { t } = useTranslation();
    const [options, setOptions] = useState<SzDualListBoxOption[]>([]);
    const [selectedOptions, setSelectedOptions] = useState<SzDualListBoxOption[]>([]);

    const fetchServices = () => ServiceService.getAllServices(1, 9999)

    const fetchContractService = () => ContractServiceService.getAllByContract(contractId, 1, 9999);

    const { data: servicesData } = useQuery("services", fetchServices);


    const { data: contractServicesData } = useQuery(["contractServices", contractId], fetchContractService);

    const serviceDataToOption = (services: Service[], contractServices: ContractService[]) => {
        return  services.map((service: Service) => {
            return { id: service.code as string, label: service.label, code: service.code }
        }).filter((option: SzDualListBoxOption) => {
            return contractServices.find((contractService: ContractService) => {
                return option.id === contractService.service.code
            }) === undefined;
        });
    }

    const filterServiceOptions = (filter: string) => {
        if (servicesData && contractServicesData) {
            setOptions(serviceDataToOption(servicesData.data, contractServicesData.data).filter((option: SzDualListBoxOption) => option.label.toLowerCase().indexOf(filter.toLowerCase()) !== -1))
        }
    }

    useEffect(() => {
        if (servicesData && contractServicesData) {
            setOptions(serviceDataToOption(servicesData.data, contractServicesData.data));
        }
    }, [servicesData, contractServicesData])

    const onSelectedChange = (selected: SzDualListBoxOption[]) => {
        setSelectedOptions(selected);
        setSelectedServices(selected.map((option: SzDualListBoxOption) => option.id));
    }

    return <>
        <div className={"d-flex"}>
            <SzDualListBox
                options={options}
                selectedOptions={selectedOptions}
                showRowIcons={true}
                onlySelectWithRowIcons={true}
                leftSideTitle={t("admin.services.modal.add.allServices")}
                rightSideTitle={t("admin.services.modal.add.selectedServices")}
                onChange={onSelectedChange}
                isScrollable={true}
                onKeywordsChange={filterServiceOptions}
                searchPlaceholder={t('search')}
            />
        </div>
        <div className={"d-flex justify-content-end p-2"}>
            <SzButton onClick={goToNext}>
                {t('next')}
            </SzButton>
        </div>
    </>;
}

export default Step1;
