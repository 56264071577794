import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import { HttpCode, ErrorRoutes } from "../../constants";
import Navigation from "../../components/navigation";

const NavigationManager = (props: any) => {
    const [isErrorPage, setIsErrorPage] = useState(false);
    const [redirectUrl, setRedirectUrl] = useState('');
    let history = useHistory();

    useEffect(() => {
        setIsErrorPage(history.location.pathname.includes('error'));
        if (props.error.status === HttpCode.NOT_FOUND || props.error.status === HttpCode.UNSUPPORTED_MEDIA) {
            setRedirectUrl(ErrorRoutes.NOT_FOUND)
        } else if (props.error.status === HttpCode.ACCESS_DENIED) {
            setRedirectUrl(ErrorRoutes.ACCESS_DENIED)
        } else {
            setRedirectUrl(ErrorRoutes.OOPS)
        }
    }, [history.location.pathname, props.error]);

    return (
        !isErrorPage && props.error.isError ?
            <Redirect to={redirectUrl}/>
            :
            <Navigation
                logged={props.user.logged}
                loading={props.loading.loading}
                isError={isErrorPage}
                user={props.user}
                contract={props.contract}
            >
                {props.children}
            </Navigation>
    );
};

const mapStateToProps = (state: any) => ({
    loading: state.loading,
    user: state.user,
    error: state.error,
    contract: state.contract.currentContract
});

export default connect(mapStateToProps)(NavigationManager);
