import axiosService from './Request';
import { ApiUrls, Constants } from '../constants';
import { UserContract, UserState } from "../types";

export class AreaService {
    public static deleteContractArea(contractId: string, areaToDeleteId:string) {
        const url = ApiUrls.CONTRACT_AREA.replace(':contractId', contractId)
            .replace(':areaId', areaToDeleteId);

        return axiosService.delete(url, [], false, true);
    }

    public static getClientAreas(contractId: string, currentPage?: number, itemsPerPage?: number, query?: string) {
        const url = ApiUrls.CONTRACT_AREAS.replace(":contractId", contractId);
        const body = {
            labelFilter: query ?? null,
            currentPage: currentPage ?? 1,
            itemsPerPage: itemsPerPage ?? Constants.DEFAULT_PER_PAGE
        }

        return axiosService.getWithParams(url, body, false, true)
            .then(response => response);
    }

    public static getClientArea(contractId: string, areaId: string) {
        const url = ApiUrls.CONTRACT_AREA
            .replace(":contractId", contractId)
            .replace(":areaId", areaId);

        return axiosService.get(url, false, true)
            .then(response => response.data);
    }

    public static createAreaAndContractArea(contractId: string, MunicipalityIds: string[]) {
        const url = ApiUrls.AREA_AND_CONTRACT_AREA_URL.replace(":contractId", contractId)

        return axiosService.post(url, MunicipalityIds);
    }

    public static getAreaOrganizations(contractId: string, areaId: string, organizationType?: string, currentPage?: number, itemsPerPage?: number) {
        const url = ApiUrls.AREA_ORGANIZATIONS.replace(":contractId", contractId).replace(":areaId", areaId);
        const body = {
            organizationType: organizationType ?? '',
            currentPage: currentPage ?? 1,
            itemsPerPage: itemsPerPage ?? Constants.DEFAULT_PER_PAGE
        }

        return axiosService.getWithParams(url, body, false, true);
    }

    public static getAreaCategories(contractId: string, areaId: string, currentPage?: number, itemsPerPage?: number) {
        const url = ApiUrls.AREA_CATEGORIES.replace(":contractId", contractId).replace(":areaId", areaId);
        const body = {
            currentPage: currentPage ?? 1,
            itemsPerPage: itemsPerPage ?? Constants.DEFAULT_PER_PAGE
        }

        return axiosService.getWithParams(url, body, false, true);
    }

    public static getAreasNbCategories(areaIds: string[], contractId: string) {
        const url = ApiUrls.AREAS_NB_CATEGORIES.replace(':contractId', contractId);
        const body = {
            areas: areaIds,
            countOnly: true
        }

        return axiosService.getWithParams(url, body);
    }

    public static getAreasNbOrganizations(areaIds: string[], contractId: string, organizationType?: string) {
        const url = ApiUrls.AREAS_NB_ORGANIZATION.replace(':contractId', contractId);
        const body = {
            areas: areaIds,
            countOnly: true,
            organizationType: organizationType
        }

        return axiosService.getWithParams(url, body);
    }

    public static getUserAreas(userId: string) {
        const url = ApiUrls.USER_AREAS.replace(':userId', userId);

        return axiosService.get(url, false, true);
    }

    public static async getUserGeoShape(user: UserState, parameters?: { serviceCode?: string, merged?: boolean, contractId?: string }) {
        const url = ApiUrls.USER_GEOSHAPE.replace(':userId', user.info.heimdallId);
        const body = {
            serviceCode: parameters && parameters.serviceCode ? parameters.serviceCode : null,
            merged: parameters && parameters.merged ? parameters.merged : null,
            contractId: parameters && parameters.contractId ? parameters.contractId : null
        };

        return axiosService.getWithParams(url, body, false, true)
            .then(response => response.data);
    }

    public static async getLoggedUserGeoShape(user: UserState, contract: UserContract, parameters?: { serviceCode?: string, merged?: boolean }) {
        let parametersWithContract = { contractId: contract.id }
        if (parameters) {
            parametersWithContract = Object.assign(parameters, parametersWithContract)
        }

        return this.getUserGeoShape(user, parametersWithContract)
    }
}
