import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useQuery, useQueryClient } from "react-query";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { SzAlert, SzIcon, SzModal, SzPagination, SzSpinner, SzTable } from "@suezenv/react-theme-components";
import { AppAdminDetailsUrls, AppUrls, Constants, OrganizationTypes } from "../../../constants";
import { CommonHelper } from "../../../helper/Common";
import { Area, Contract, Pagination } from "../../../types";
import { AreaService } from "../../../services/AreaService";
import TabHeader from "../adminTabs/tabHeader";
import AdminAreasAdd from "./AdminAreasAdd";
import ConfirmationModal from "../../elements/ConfirmationModal";

type AreaData = {
    actions: JSX.Element,
    exploitant: string | JSX.Element,
    id: string,
    label: string,
    organization: string | JSX.Element,
    skillSet: string | JSX.Element,
}

const AdminAreas = (props: { contractSelected?: Contract, currentContract: Contract, readonly?: boolean}) => {
    const history = useHistory();
    const location = useLocation();
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const { currentContract, contractSelected, readonly } = props;
    const usedContract = contractSelected ?? currentContract;
    const [currentPage, setCurrentPage] = useState(1);
    const [areasData, setAreasData] = useState<AreaData[]>();
    const [showAddModal, setShowAddModal] = useState(false);
    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
    const [areaToDeleteLabel, setAreaToDeleteLabel] = useState<string>("");
    const [areaToDeleteId, setAreaToDeleteId] = useState<string>("");
    const [pagination, setPagination] = useState<Pagination>();
    const [queryFilter, setQueryFilter] = useState<string>("");

    const fetchAreas = () => {
        return AreaService.getClientAreas(usedContract.id, currentPage, undefined, queryFilter);
    };

    const {
        data,
        isError,
        isLoading,
        isSuccess
    } = useQuery(['clientAreas', usedContract.id, queryFilter, currentPage], fetchAreas);

    const fetchAreasNbCategories = () => AreaService.getAreasNbCategories(data?.data.map((area: Area) => area.id), usedContract.id);
    const fetchAreasNbOrganizations = () => AreaService.getAreasNbOrganizations(data?.data.map((area: Area) => area.id), usedContract.id, OrganizationTypes.COMMUNITY);
    const fetchAreasNbOperator = () => AreaService.getAreasNbOrganizations(data?.data.map((area: Area) => area.id), usedContract.id, OrganizationTypes.OPERATOR);

    const {
        data: nbCategories,
        isError: nbCategoriesError
    } = useQuery(["areasNbCategories", usedContract.id, currentPage], fetchAreasNbCategories, { enabled: isSuccess });
    const {
        data: nbOrganizations,
        isError: nbOrganizationsError
    } = useQuery(["areasNbOrganizations", usedContract.id, currentPage], fetchAreasNbOrganizations, { enabled: isSuccess });
    const {
        data: nbOperators,
        isError: nbOperatorsError
    } = useQuery(["areasNbOperators", usedContract.id, currentPage], fetchAreasNbOperator, { enabled: isSuccess });

    useEffect(() => {
        if (isSuccess) {
            const areas: Area[] = data?.data
            let areaData: AreaData[] = [];
            areas.forEach((area: Area) => areaData.push(formatData(area)));
            setAreasData(areaData);
            setPagination(CommonHelper.getPagination(areas.length, data?.headers));
        }
    }, [data]);

    useEffect(() => {
        if (areasData) {
            let tmpAreaData = areasData?.slice(0);
            tmpAreaData = tmpAreaData.map((areaData: AreaData) => {
                return {
                    ...areaData,
                    skillSet: nbCategories?.data ? CommonHelper.findEntityCount(Object.entries(nbCategories?.data), nbCategoriesError, areaData.id) :
                        <SzSpinner/>,
                    exploitant: nbOperators?.data ? CommonHelper.findEntityCount(Object.entries(nbOperators?.data), nbOperatorsError, areaData.id) :
                        <SzSpinner/>,
                    organization: nbOrganizations?.data ? CommonHelper.findEntityCount(Object.entries(nbOrganizations?.data), nbOrganizationsError, areaData.id) :
                        <SzSpinner/>
                };
            });
            setAreasData(tmpAreaData);
        }
    }, [nbCategories, nbCategoriesError, nbOrganizations, nbOrganizationsError, nbOperators, nbOperatorsError]);

    const defaultSorted: any = [
        {
            dataField: 'name',
            order: 'desc'
        }
    ];

    const columns = [
        {
            dataField: 'label',
            text: t('admin.area.label')
        },
        {
            dataField: 'skillSet',
            text: t('admin.area.skillSet')
        },
        {
            dataField: 'exploitant',
            text: t('admin.area.exploitant')
        },
        {
            dataField: 'organization',
            text: t('admin.area.organization')
        }
    ];

    if (!readonly) {
        columns.push(
            {
                dataField: 'actions',
                text: ''
            })
    }

    const deleteContractAreaHandler = () => {
        setShowDeleteConfirmationModal(false);

        AreaService.deleteContractArea(usedContract.id, areaToDeleteId)
            .then(refreshArea)
            .then(hideDeleteAreaConfirmationModal)
        ;
    }

    const goToContractAreaDetails = (contractId: string, areaId: string) => {
        history.push(CommonHelper.generateAdminDetailsSubRoute(location.pathname, AppAdminDetailsUrls.AREA_DETAILS)
            .replace(':contractId', contractId)
            .replace(':areaId', areaId)
        );
    }

    const formatData = (area: Area): AreaData => {
        return {
            id: area.id,
            label: area.label ?? "",
            exploitant: nbOperators?.data ? CommonHelper.findEntityCount(Object.entries(nbOperators?.data), nbOperatorsError, area.id) :
                <SzSpinner/>,
            organization: nbOrganizations?.data ? CommonHelper.findEntityCount(Object.entries(nbOrganizations?.data), nbOrganizationsError, area.id) :
                <SzSpinner/>,
            skillSet: nbCategories?.data ? CommonHelper.findEntityCount(Object.entries(nbCategories?.data), nbCategoriesError, area.id) :
                <SzSpinner/>,
            actions: <div className="text-right">
                <SzIcon variant='line' icon='task-list-text-1' className="color-primary"
                        onClick={() => goToContractAreaDetails(usedContract.id, area.id)}/>
                <SzIcon variant='line' icon='bin-2-alternate' className="color-danger"
                        onClick={() => {showDeleteAreaConfirmationModal(area.id, area.name ?? (area.label ?? ""));}}/>
            </div>
        }
    };

    const handlePageChange = (pageNumber: number) => setCurrentPage(pageNumber);
    const refreshArea = () => {
        queryClient.invalidateQueries(['clientAreas', usedContract.id]);
    }
    const renderPagination = (pagination: Pagination) => {
        return <div className="d-flex justify-content-center my-4">
            <SzPagination
                totalItemsCount={pagination.totalCount}
                activePage={currentPage}
                onChange={handlePageChange}
                itemsCountPerPage={pagination.perPage}
                pageRangeDisplayed={+Constants.USERS_PAGINATION_PAGE_RANGE}
            />
        </div>;
    }
    const showDeleteAreaConfirmationModal = (areaId: string, areaName: string) => {
        setAreaToDeleteId(areaId);
        setAreaToDeleteLabel(areaName);
        setShowDeleteConfirmationModal(true);
    }
    const hideDeleteAreaConfirmationModal = () => {
        setShowDeleteConfirmationModal(false);
    }

    const onAddFormValidate = () => {
        queryClient.invalidateQueries(['clientAreas', usedContract.id]);
        queryClient.invalidateQueries(['areasNbCategories', usedContract.id]);
        queryClient.invalidateQueries(['areasNbOrganizations', usedContract.id]);
        queryClient.invalidateQueries(['areasNbOperators', usedContract.id]);
    }

    if (isError) {
        return <SzAlert
            variant="warning"
            transparent={true}
            className="w-100 border-0 flex-column p-4"
        >
            {t('alert.loading_error')}
        </SzAlert>
    }

    return <div className="box">
        <TabHeader title={t('admin.tabs.areas')}
                subtitle={t("admin.tabs.areasSubtitle", { count: pagination?.totalCount })}
                onBtnClick={location.pathname !== AppUrls.ADMIN_GESTION_TERRITORIALE && !readonly ? () => setShowAddModal(true) : undefined}
                onSearch={setQueryFilter}
        />

        {isLoading && <div className='p-4 text-center'>
                <SzSpinner/>
            </div>
        }

        {!isLoading && areasData && <SzTable
            data={areasData}
            keyField={'label'}
            columns={columns}
            defaultSorted={defaultSorted}
        />}

        {!isLoading && pagination && renderPagination(pagination)}

        <SzModal
            show={showAddModal}
            handleClose={() => setShowAddModal(false)}
            size={"xl"}
            centered={true}
            title={t('admin.area.modal.add.title')}
        >
            <AdminAreasAdd
                currentContract={usedContract.id}
                countryCode ={usedContract?.contractMetadatas?.contract_country_code || 'FR'}
                refreshData={onAddFormValidate}
            />
        </SzModal>
        <ConfirmationModal
            show={showDeleteConfirmationModal}
            onValidate={deleteContractAreaHandler}
            onCancel={hideDeleteAreaConfirmationModal}
            displayCancelBtn={true}
            modalBody={t('admin.area.modal.delete.body', {
                id: areaToDeleteId,
                label: areaToDeleteLabel
            })}
            modalTitle={t('admin.area.modal.delete.title')}
        />
    </div>
}

const mapStateToProps = (state: { contract: { currentContract: Contract } }) => ({
    currentContract: state.contract.currentContract
});

export default connect(mapStateToProps)(AdminAreas);
