import { Constants } from "../constants";

const themeReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Constants.PRIMARY_LOGO:
            return { ...state, primaryLogo: action.payload };
        case Constants.SECONDARY_LOGO:
            return { ...state,secondaryLogo: action.payload };
        case Constants.PRIMARY_COLOR:
            return { ...state, primaryColor: action.payload };
        case Constants.SECONDARY_COLOR:
            return { ...state, secondaryColor: action.payload };
        default:
            return state;
    }
};

export default themeReducer;
