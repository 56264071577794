import { Services, widgetTypes } from "./index";
import { WidgetConf, WidgetData } from "../types"

export const HydrometricRiviereOucheData: WidgetData[] = [{
    id: '71e08a76-ff01-4caa-a59f-84c9043c65ac'
}]

export const HydrometricLacKirData: WidgetData[] = [{
    id: 'U132401001'
}]

export const WaterCycleVolumeProducedData: WidgetData[] = [{
    id: 'volume_produced'
}]

export const WaterCycleVolumeStoredData: WidgetData[] = [{
    id: 'volume_stored'
}]

export const WaterCycleVolumeDealtData: WidgetData[] = [{
    id: 'volume_dealt'
}]

export const WaterCycleVolumeOutputData: WidgetData[] = [{
    id: 'volume_output'
}]


const widgetsConf: WidgetConf[] = [
    {
        data: HydrometricRiviereOucheData,
        id: '65e82d80-e996-4568-bb62-da4bd94f34fb',
        label: 'Rivière Ouche',
        service: Services.HYDROMETRIC,
        type: widgetTypes.HYDROMETRIC
    },
    {
        id: 'abb4ee84-78aa-420b-9747-169318c08eab',
        label: 'Lac Kir',
        service: Services.HYDROMETRIC,
        data: HydrometricLacKirData,
        type: widgetTypes.HYDROMETRIC
    },
    // {
    //     id: '043a48e8-8c24-421f-aec5-cf6d93ccb8db',
    //     label: 'Interventions',
    //     service: Services.INTERVENTIONS,
    //     data: []
    // },
    {
        id: '65f3fcc5-15ea-4388-ba6c-979c07387398',
        label: 'Analyse conformité eau potable',
        service: Services.WATER_CONFORMITY,
        data: [],
        type: widgetTypes.WATER_CONFORMITY
    },
    {
        id: '16ce6733-ebc5-476e-b991-75a19b4eef22',
        label: 'Volume produit hier (en m³)',
        service: Services.WATER_CYCLE,
        data: WaterCycleVolumeProducedData,
        type: widgetTypes.WATER_CYCLE
    },
    {
        id: '3f75b148-f58a-4edc-bfb1-85c60eceb856',
        label: 'Stockage ce matin (en m³)',
        service: Services.WATER_CYCLE,
        data: WaterCycleVolumeStoredData,
        type: widgetTypes.WATER_CYCLE
    },
    {
        id: '05e1eddb-79d2-4fcf-af4e-1182a1e66701',
        label: 'Volume traité hier (en m³)',
        service: Services.WATER_CYCLE,
        data: WaterCycleVolumeDealtData,
        type: widgetTypes.WATER_CYCLE
    },
    {
        id: 'c1dccec1-bc83-42c5-ab69-b7b463b2f581',
        label: 'Volume déversé hier (en m³)',
        service: Services.WATER_CYCLE,
        data: WaterCycleVolumeOutputData,
        type: widgetTypes.WATER_CYCLE
    },
    {
        id: 'a0044ef6-d9ff-47c6-844d-36e1cc46c4cb',
        label: "Suivi Signalement (Nouveaux)",
        service: Services.SIGNAL_NOTIFY,
        data: [],
        type: widgetTypes.SIGNAL_STAT_CONDENSED
    },
    {
        id: '1ec17308-1296-4da9-96c8-fbf477c98bc8',
        label: "Suivi Signalement",
        service: Services.SIGNAL_NOTIFY,
        data: [],
        type: widgetTypes.SIGNAL_STAT_FULL
    },
    {
        id: '91ab5a35-6efa-4330-aa6f-6fcaee13bc65',
        label: "Signalement: Top 5 des catégories",
        service: Services.SIGNAL_NOTIFY,
        data: [],
        type: widgetTypes.SIGNAL_TOP_CATEGORY
    },
    {
        id: 'abfg5c23-ab15-7251-aa6f-6fcaee25bc65',
        label: "Notifications",
        service: Services.VA_GEST_DOC,
        data: [],
        type: widgetTypes.DOCUMENT_NOTIFICATION
    },
    {
        id: '5ffa5c00-cb4a-c1236-cfcb-5bca2525bc3c',
        label: "Nouveaux documents",
        service: Services.VA_GEST_DOC,
        data: [],
        type: widgetTypes.LAST_DOCUMENTS
    },
    {
        id: '25ac98df-1548-a3652-fg3f-12ef2a554ead4',
        label: "Nombre de document",
        service: Services.VA_GEST_DOC,
        data: [],
        type: widgetTypes.NUMBER_DOCUMENTS
    },
    {
        id: '77845999-55f9-49cf-811f-91990ecb9098a6',
        label: "Suivi des travaux",
        service: Services.PUBLIC_SPACE_PLANNER,
        data: [],
        type: widgetTypes.PUBLIC_SPACE_PLANNER_STAT_FULL
    },
    {
        id: '5d405cb4-a238-11ed-a8fc-0242ac120002',
        label: "Statut des demandes",
        service: Services.REQUEST,
        data: [],
        type: widgetTypes.REQUEST_STATUS
    }
];

export default widgetsConf;
