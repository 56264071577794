import { ServiceContract, UserContract, UserState } from "../types";

export class UserHelper {
    public static getServiceOrganization(user: UserState, serviceName: string): string {
        const services = user.info.services[serviceName];
        const service = services.filter((serviceContract: ServiceContract) => 'organization' === serviceContract.type)

        return 1 === service.length ? service[0].id : '';
    }

    public static getServicesForCurrentUserContract(user: UserState, contract: UserContract): string[] {
        const services = user.info.services;
        let contractServices: any = []

        for (let [serviceName, serviceData] of Object.entries(services)) {
            let data = serviceData.filter((meta: ServiceContract) => 
                meta.type === 'contract' && meta.id === contract?.id
            )

            if (data.length > 0) {
                contractServices.push(serviceName)
            }
        }

        return contractServices
    }

    public static getAdminRoles(user: UserState): string[] {
        let serviceNames = Object.entries(user.info.services).map(([name]) => name)
        let adminRoles: any = []

        for (let role of user.info.roles) {
            // Keep only service that have associated role name
            if (!serviceNames.includes(this.roleToService(role))) {
                adminRoles.push(role)
            }
        }

        return adminRoles
    }

    public static rolesToService(roles: string[]) {
        return roles.map((role: string) => this.roleToService(role))
    }
    
    // Transform "ROLE_myservicename" to "myservicename"
    public static roleToService(role: string) {
        return role.replace(/^ROLE_/, '')
    }
}
