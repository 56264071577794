import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SzDualListBox, { SzDualListBoxOption } from "../../../elements/SzDualListBox";
import { useQuery } from "react-query";
import { Area, Contract } from "../../../../types";
import { AreaService } from "../../../../services/AreaService";

type PropsType = {
    setAreas: (areaIds: string[]) => void,
    areas: string[];
    contract: Contract,
    defaultSelectedArea: SzDualListBoxOption[]|undefined;
}

const Step2 = (props: PropsType) => {
    const { t } = useTranslation();
    const [options, setOptions] = useState<SzDualListBoxOption[]>([]);
    const [selectedOptions, setSelectedOptions] = useState<SzDualListBoxOption[]>([]);

    const fetchContractAreas = () => {
        return AreaService.getClientAreas(props.contract.id, 1, 9999);
    }

    const { data : areaData } = useQuery(["clientAreas", props.contract.id], fetchContractAreas);

    const areaDataToOption = (areas: Area[]) => {
        return  areas.map((area: Area) => {
            return { id: area.id as string, label: area.label } as SzDualListBoxOption;
        }).sort((a: SzDualListBoxOption, b: SzDualListBoxOption) => {
            return a.label.localeCompare(b.label);
        });
    }

    const filterAreaOptions = (filter: string) => {
        if (areaData) {
            setOptions(areaDataToOption(areaData.data).filter((option: SzDualListBoxOption) => option.label.toLowerCase().indexOf(filter.toLowerCase()) !== -1))
        }
    }

    useEffect(() => {
        if (props.defaultSelectedArea !== undefined) {
            setSelectedOptions(props.defaultSelectedArea);
        } else {
            setSelectedOptions([]);
        }

    }, []);

    useEffect(() => {
        if (areaData) {
            setOptions(areaDataToOption(areaData.data));
        }
    }, [areaData]);

    useEffect(() => {
        props.setAreas(selectedOptions.map((selected: SzDualListBoxOption) => selected.id));
    }, [selectedOptions])

    return <SzDualListBox
        options={options}
        selectedOptions={selectedOptions}
        showRowIcons={true}
        onlySelectWithRowIcons={true}
        leftSideTitle={t("admin.organization.modal.add.allArea")}
        rightSideTitle={t("admin.organization.modal.add.selectedArea")}
        onChange={setSelectedOptions}
        isScrollable={true}
        onKeywordsChange={filterAreaOptions}
        searchPlaceholder={t('search')}
        />
}

export default Step2;