import { Constants } from "../constants";

const contractReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Constants.CURRENT_CONTRACT:
            return { ...state, currentContract: action.payload };
        default:
            return state;
    }
};

export default contractReducer;
