import { Constants } from "../constants";

const permissionsReducer = (state = {}, action: any) => {
    switch (action.type) {
        case Constants.USER_SKILLS:
            return { ...state, skills: action.payload };
        default:
            return state;
    }
};

export default permissionsReducer;
