import { ActionTypes } from "../constants";
import { IGetUserConfigurationAction, UserConfigurationState } from "../types";

const userConfigurationReducer = (state: UserConfigurationState = {}, action: IGetUserConfigurationAction) => {
    switch (action.type) {
        case ActionTypes.GET_USER_CONFIGURATION:
            return { ...state, [action.payload.code]: action.payload.data} ;
        default:
            return state;
    }
};

export default userConfigurationReducer;
