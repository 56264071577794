import { UserContract, UserState } from '../types';
import { UserHelper } from './UserHelper';

export class RightsHelper {
    public static isGranted(user: UserState, serviceRoles: string[]): boolean {
        const { roles } = user.info;

        if (roles === undefined) {
            return false;
        }

        let intersection = roles.filter(x => serviceRoles.includes(x));

        return intersection.length > 0;
    }

    public static isGrantedForCurrentContract(user: UserState, contract: UserContract, serviceRoles: string[]): boolean {
        let currentContractServices = UserHelper.getServicesForCurrentUserContract(user, contract)
        let serviceRolesInCurrentContratServices = UserHelper.rolesToService(serviceRoles).filter((value) => currentContractServices.includes(value))

        return this.isGranted(user, serviceRoles) && serviceRolesInCurrentContratServices.length > 0
    }
}
