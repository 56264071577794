import { datadogRum } from "@datadog/browser-rum";
import { config } from "../config";

datadogRum.init({
  // applicationId: "cd0ac9f1-3017-4062-894a-89778311c037",
  applicationId: config.datadogApplicationId,
  //   clientToken: "pubff6013ad6e705f038e8f3158083a37e7",
  clientToken: config.datadogClientToken,
  site: "datadoghq.eu",
  service: config.datadogNameService,
  env: config.envDeploiement,
  allowedTracingUrls: [/https:\/\/(?!geoserver).*\.villagile\.fr/],
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: "mask-user-input",
});
