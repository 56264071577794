declare global {
    interface Window {
      REACT_APP_ANALYTICS_KEY: any;
      REACT_APP_API_HOST: any;
      REACT_APP_DEFAULT_URL: string;
      REACT_APP_OPEN_API_URL: string;
      REACT_APP_SUPPORT_HOST: any;
      REACT_APP_TOKEN_KEY: string;
      REACT_APP_GEOSERVER_URL: string;
      REACT_APP_GEOSERVER_AUTH_KEY: string;
      REACT_APP_MAYAN_HOST: string;
      REACT_APP_THINGS_BOARD_URL: string;
      REACT_APP_GOOGLE_ANALYTICS_TOKEN: string;
      REACT_APP_MATOMO_URL_BASE: string;
      REACT_APP_MATOMO_SITE_ID: number;
      REACT_APP_MATOMO_SET_SECURE_COOKIE: boolean;
      REACT_APP_MATOMO_DISABLED: boolean;
      REACT_APP_MATOMO_DIMENSION_CONTRACT_ID: number;
      REACT_APP_MATOMO_DIMENSION_SERVICE_ID: number;
      REACT_APP_DATADOG_APPLICATION_ID: string;
      REACT_APP_DATADOG_CLIENT_TOKEN: string;
      REACT_APP_DATADOG_ALLOWED_TRACING_URLS: string;
      REACT_APP_DATADOG_SERVICE_NAME: string;
      REACT_APP_ENV_DEPLOYMENT: string;
    }
}

let apiUrl = window.REACT_APP_API_HOST || process.env.REACT_APP_API_HOST;

// force test env (use REACT_APP_API_TEST_HOST for api calls)
const url = new URL(window.location.href);
const testEnv = url.searchParams.get("test_env");
if (testEnv) {
    localStorage.setItem("test_env", testEnv);
}
const isTestEnv = localStorage.getItem("test_env");
if (process.env.REACT_APP_API_TEST_HOST && isTestEnv && 1 === parseInt(isTestEnv)) {
    apiUrl = process.env.REACT_APP_API_TEST_HOST;
}
export const config = {
    apiUrl,
    defaultUrl: window.REACT_APP_DEFAULT_URL || process.env.REACT_APP_DEFAULT_URL,
    geoserverUrl: window.REACT_APP_GEOSERVER_URL || process.env.REACT_APP_GEOSERVER_URL,
    geoserverAuthKey: window.REACT_APP_GEOSERVER_AUTH_KEY || process.env.REACT_APP_GEOSERVER_AUTH_KEY,
    googleAnalyticsId: window.REACT_APP_ANALYTICS_KEY || process.env.REACT_APP_ANALYTICS_KEY,
    portalOpenApiUrl: window.REACT_APP_OPEN_API_URL || process.env.REACT_APP_OPEN_API_URL,
    portalThingsboardUrl: window.REACT_APP_THINGS_BOARD_URL || process.env.REACT_APP_THINGS_BOARD_URL,
    supportUrl: window.REACT_APP_SUPPORT_HOST || process.env.REACT_APP_SUPPORT_HOST,
    tokenKey: window.REACT_APP_TOKEN_KEY || process.env.REACT_APP_TOKEN_KEY || '',
    mayanUrl: window.REACT_APP_MAYAN_HOST || process.env.REACT_APP_MAYAN_HOST || '',
    googleAnalyticsToken: window.REACT_APP_GOOGLE_ANALYTICS_TOKEN || process.env.REACT_APP_GOOGLE_ANALYTICS_TOKEN || '',
    matomoUrlBase: window.REACT_APP_MATOMO_URL_BASE || process.env.REACT_APP_MATOMO_URL_BASE || '',
    matomoSiteId: (window.REACT_APP_MATOMO_SITE_ID || process.env.REACT_APP_MATOMO_SITE_ID) as number,
    matomoSetSecureCookie: (window.REACT_APP_MATOMO_SET_SECURE_COOKIE || process.env.REACT_APP_MATOMO_SET_SECURE_COOKIE) === "true",
    matomoDisabled: (window.REACT_APP_MATOMO_DISABLED || process.env.REACT_APP_MATOMO_DISABLED) === "true",
    matomoDimensionContractId: window.REACT_APP_MATOMO_DIMENSION_CONTRACT_ID || process.env.REACT_APP_MATOMO_DIMENSION_CONTRACT_ID,
    matomoDimensionServiceId: window.REACT_APP_MATOMO_DIMENSION_SERVICE_ID || process.env.REACT_APP_MATOMO_DIMENSION_SERVICE_ID,
    datadogApplicationId: window.REACT_APP_DATADOG_APPLICATION_ID || process.env.REACT_APP_DATADOG_APPLICATION_ID || '',
    datadogClientToken: window.REACT_APP_DATADOG_CLIENT_TOKEN || process.env.REACT_APP_DATADOG_CLIENT_TOKEN || '',
    datadogAllowedTrackingUrls: window.REACT_APP_DATADOG_ALLOWED_TRACING_URLS?.split(',') || process.env.REACT_APP_DATADOG_ALLOWED_TRACING_URLS?.split(',') || '',
    datadogNameService: window.REACT_APP_DATADOG_SERVICE_NAME || process.env.REACT_APP_DATADOG_SERVICE_NAME || '',
    envDeploiement: window.REACT_APP_ENV_DEPLOYMENT || process.env.REACT_APP_ENV_DEPLOYMENT || ''
};
