import React, {useEffect, useState} from 'react';
import { MapContainer } from 'react-leaflet';
import "./map.scss";
import FitBoundMap from "./FitBoundMap";

interface IMapComponent {
    zooms: any
    refs: any
    children?: any
    className?: string
    zoomControl?: boolean
    center?: any
    geoShapes?: any
}

const MapComponent = (props: IMapComponent) => {
    const { children, zooms, refs, ...rest } = props;
    const [bounds, setBounds] = useState<any>(null);

    useEffect(() => {
        setTimeout(function () {
            if (refs.current) {
                setBounds(refs.current.getBounds());
            }
        }, 1000)
    }, [refs.current])

    return (
        <MapContainer
            {...zooms}
            {...rest}
        >
            {bounds &&
                <FitBoundMap bounds={bounds}/>
            }
            {children}
        </MapContainer>
    );
};

export default MapComponent;
