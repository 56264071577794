import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SzDualListBox, { SzDualListBoxOption } from "../../../../elements/SzDualListBox";
import { ContractService, Organization, Service } from "../../../../../types";
import { OrganizationService } from "../../../../../services/OrganizationService";
import { useQuery } from "react-query";
import { ContractServiceService } from "../../../../../services/ContractServiceService";
import { SzButton } from "@suezenv/react-theme-components";

const Step1 = (props: { setSelectedServices: (services: string[]) => void, setSelectedContractServices: (services: string[]) => void, organizationId: string, goToNext: () => void }) => {
    const { setSelectedServices, organizationId, setSelectedContractServices, goToNext } = props;
    const { t } = useTranslation();
    const [options, setOptions] = useState<SzDualListBoxOption[]>([]);
    const [selectedOptions, setSelectedOptions] = useState<SzDualListBoxOption[]>([]);
    const [skillSets, setSkillSets] = useState<string[]>([]);

    const fetchOrganization = () => OrganizationService.getOrganization(organizationId);
    const fetchOrganizationServices = () => OrganizationService.getOrganizationServices(organizationId, 1, 9999);

    const { data: organization } = useQuery<Organization>(['organization', organizationId], fetchOrganization);
    const { data: organizationServicesData } = useQuery(['organizationServices', organizationId], fetchOrganizationServices);

    const fetchContractServices = () => organization?.contractId ? ContractServiceService.getAllByContract(organization.contractId, 1, 9999, skillSets, true) : null;
    const { data: contractServicesData } = useQuery(["contractService", organization?.contractId, skillSets], fetchContractServices, { enabled: typeof organization !== "undefined" });


    const contractServiceDataToOption = (contractServices: ContractService[], organizationServices: Service[]) => {
        return contractServices
            .filter((contractService: ContractService) => {
                return !organizationServices.find((service: Service) => service.code === contractService.service.code);
            })
            .map((contractService: ContractService) => {
                return { id: contractService.id, label: contractService.service.label }
            });
    }

    const filterServiceOptions = (filter: string) => {
        if (contractServicesData && organizationServicesData) {
            setOptions(
                contractServiceDataToOption(contractServicesData.data, organizationServicesData.data)
                    .filter((option: SzDualListBoxOption) => option.label.toLowerCase().indexOf(filter.toLowerCase()) !== -1)
            );
        }
    }


    useEffect(() => {
        if (organization && organization.category) {
            setSkillSets(organization.category.map((category: { id: string }) => category.id));
        }
    }, [organization]);

    useEffect(() => {
        if (contractServicesData && organizationServicesData) {
            setOptions(contractServiceDataToOption(contractServicesData.data, organizationServicesData.data));
        }
    }, [contractServicesData, organizationServicesData]);

    const onSelectedChange = (selected: SzDualListBoxOption[]) => {
        setSelectedOptions(selected);
        setSelectedContractServices(selected.map((option: SzDualListBoxOption) => option.id));
        setSelectedServices(selected.map((option: SzDualListBoxOption) => {
            const contractService = contractServicesData?.data.find((contractService: ContractService) => contractService.id === option.id);
            if (contractService) {
                return contractService.service.code;
            }
            return "";
        }))
    }

    return <>
        <div className={"d-flex"}>
            <SzDualListBox
                options={options}
                selectedOptions={selectedOptions}
                showRowIcons={true}
                onlySelectWithRowIcons={true}
                leftSideTitle={t("admin.services.modal.add.allServices")}
                rightSideTitle={t("admin.services.modal.add.selectedServices")}
                onChange={onSelectedChange}
                isScrollable={true}
                onKeywordsChange={filterServiceOptions}
                searchPlaceholder={t('search')}
            />
        </div>
        <div className={"d-flex justify-content-end p-2"}>
            <SzButton onClick={goToNext}>
                {t('next')}
            </SzButton>
        </div>
    </>;
}

export default Step1;
